<div class="modal fade" id="zintGrowVideoModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="zintGrowVideoModalLabel"><i class="fal fa-bolt"></i> Introducing Zint Grow</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <div class="embed-responsive embed-responsive-16by9 embed-container">
                            <iframe class="embed-responsive-item" [src]="iframeSrc | safe" credentialless loading="lazy" title="Zint Grow" frameborder="0" allow="autoplay; accelerometer 'none'; browsing-topics 'none'; camera 'none'; display-capture 'none'; encrypted-media 'none'; gamepad 'none'; geolocation 'none'; gyroscope 'none'; hid 'none'; identity-credentials-get 'none'; idle-detection 'none'; local-fonts 'none'; magnetometer 'none'; microphone 'none'; midi 'none'; otp-credentials 'none'; payment 'none'; publickey-credentials-get 'none'; screen-wake-lock 'none'; serial 'none'; usb 'none'; window-management 'none'; xr-spatial-tracking 'none';" allowfullscreen></iframe>
                                <i *ngIf="showPlayIcon" class="fas fa-play" (click)="populateYoutubeUrl()"></i>
                        </div>
                        <button class="basic-button-base button-purple mt-3" (click)="requestZintGrowActivation();" data-dismiss="modal">Activate On Your Account</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


