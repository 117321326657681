@for (KV of allNewsToRender | keyvalue; track $index) {

@if (KV.key !== 'twitter_handle' && KV.value) {
<div class="news-container">
  <div class="news-header-container">
    <h5 class="mb-0">{{KV.key === 'news' ? "News Publications" : "Self-Published Articles"}}</h5>
    <div class="search-reset-container">
      <button class="rounded-btn-base rounded-btn-light btn-p-xs" (click)="resetSearchInput(KV.key)">
        <i class="fal fa-undo"></i>
      </button>
      <div class="search-input">
        <i class="fal fa-search"></i>
        <input type="text" placeholder="Search article"
          [ngModel]="KV.key === 'news' ? newsSearchTerm : selfPubNewsSearchTerm"
          (ngModelChange)="KV.key === 'news' ? newsSearchTerm = $event : selfPubNewsSearchTerm = $event"
          (keyup.enter)="performNewsSearch(KV.key)">
      </div>
    </div>
  </div>
  <div [id]="'newsCarousel' + $index" class="carousel slide news-carousel"
    [ngClass]="{'show-carousel-controls': KV.value?.length > (isSmallScreen ? 2 : 3)}" data-ride="carousel"
    data-interval="false">
    @if (KV.value.length > (isSmallScreen ? 2 : 3)) {

    <div class="controls-wrapper" [ngStyle]="{'width': shouldShowWideControls()}">
      <a class="custom-control-left" [href]="'#newsCarousel' + $index" data-slide="prev">
        <i role="button" class="fas fa-caret-circle-left"></i>
      </a>
      <ol class="carousel-indicators">
        <li *ngFor="let chunk of chunkArticles(KV.value); let j = index" [class.active]="j === 0"
          [attr.data-target]="'#newsCarousel' + $index" [attr.data-slide-to]="j">
        </li>
      </ol>
      <a class="custom-control-right" [href]="'#newsCarousel' + $index" data-slide="next">
        <i role="button" class="fas fa-caret-circle-right"></i>
      </a>
    </div>
    }

    <!-- Carousel slides -->
    <div class="carousel-inner">
      @for (chunk of chunkArticles(KV.value); track $index) {
      <div class="carousel-item" [ngClass]="{'active': KV.value.indexOf(chunk[0]) === 0}">
        <div class="article-card-container">
          @for (newsObj of chunk; track $index) {

          @if (isThirdPartyNews(newsObj)) {
          <div class="carousel-card">
            <div class="news-details">
              <span>{{newsObj.published_date | date: 'd MMM yyyy'}}</span>
              <h6 class="mt-1 mb-2">
                <i class="fal fa-newspaper mr-1"></i>
                <a [href]="newsObj.article_link" target="_blank">{{newsObj.source}}</a>
              </h6>
              <h6 class="text-bold-600">{{newsObj.title}}</h6>
              @if (newsObj.description) {
              <span class="text-grey-600" [innerHTML]="newsObj.description | safe:'html'"></span>
              }
            </div>
            <a role="button" class="rounded-btn-base rounded-btn-light btn-p-md btn-link" [href]="newsObj.article_link"
              target="_blank">Open page</a>
          </div>
          }

          @else {
          <div class="carousel-card">
            <div class="news-details">
              <span>{{newsObj.article_published_date | date: 'd MMM yyyy'}}</span>
              <h6 class="mt-1 mb-2">
                <i class="fal fa-newspaper mr-1"></i>
                <a [href]="newsObj.url" target="_blank">News From Company Website
                </a>
              </h6>
              <h6 class="text-bold-600">{{newsObj.title}}</h6>
              @if (newsObj.article_description) {
              <span class="text-grey-600" [innerHTML]="newsObj.article_description | safe: 'html'"></span>
              }
            </div>
            <a role="button" class="rounded-btn-base rounded-btn-light btn-p-md btn-link" target="_blank"
              [href]="newsObj.url">Open
              page</a>
          </div>

          }
          }
        </div>
      </div>
      }
    </div>
  </div>
</div>
}
}