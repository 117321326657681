import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'opensearch-modal',
  templateUrl: './opensearch-modal.component.html',
  styleUrl: './opensearch-modal.component.css',
})
export class OpensearchModalComponent {
  @Input() openSearchModalTitle: string;
  @Input() currentOpenSearchType: string;
  @Input() accountsResults: any;
  @Input() fullWebsiteResults: any;
  @Input() yearAccountsMadeUpToDates: string[];
  @Input() fullWebsiteSearchTerm: string;
  @Input() accountsSearchText: string;
  @Input() accountsDateSelected: string;

  @Input() loadingStatus: Record<string, boolean> = {};

  @Output()
  emitWebsiteSearch = new EventEmitter();
  @Output() emitAccountsSearch = new EventEmitter();

  constructor() {}

  fullWebsiteSearch(): void {
    this.emitWebsiteSearch.emit(this.fullWebsiteSearchTerm);
  }

  accountsSearch(): void {
    this.emitAccountsSearch.emit({
      selectedDate: this.accountsDateSelected,
      searchTerm: this.accountsSearchText,
    });
  }
}
