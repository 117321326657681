<main class="mx-auto" [ngClass]="containerClass">
  <header class="p-3 border-bottom">
    <h5 class="mb-0">{{header}}</h5>
  </header>
  <section class="body">
    <div class="thread-box" #chatbox>

      <div class="d-flex justify-content-end">
        <p class="msg">By using this agent, you agree to our agent T&Cs.</p>
      </div>

      <div *ngIf="messageThread.length">

        <div class="pr-2 pl-1" *ngFor="let msg of messageThread; let index">
          <div [id]="'user'+index" class="d-flex justify-content-start">
            <p *ngIf="msg.sender === 'user'" class="msg user">{{msg.message}}</p>
          </div>
          <div class="d-flex justify-content-end">
            <p *ngIf="msg.sender === 'response'" class="msg response" [innerHTML]="msg.message | safe: 'html'"></p>
          </div>
        </div>

      </div>


    </div>

    <footer class="footer bg-white d-flex justify-content-between">
      <input type="text number" class="form-control" placeholder="Type a message..." [(ngModel)]="userInput"
        (keyup.enter)="sendMessage()">
      <zint-loader *ngIf="isLoading" class="send-icon" size="xSmall"></zint-loader>
      <i *ngIf="!isLoading" class="far fa-arrow-circle-right send-icon" (click)="sendMessage()"></i>
    </footer>
  </section>

</main>
