import {Component, NgZone} from '@angular/core';
import {DataSharingService} from '../../services/data-sharing.service';
import {publicMethods} from '../../globals';
import {CompanyService} from '../../services/company.service';
declare var $: any;

@Component({
  selector: 'custom-insights',
  templateUrl: './custom-insights.component.html',
  styleUrl: './custom-insights.component.css'
})
export class CustomInsightsComponent {

  companyIds = [];

  constructor(
    private ngZone: NgZone,
    private dataSharingService: DataSharingService,
    private companyService: CompanyService,
    private pubMethods: publicMethods
  ) {}

  loadingState = this.companyService.isLoading;

  ngOnInit(): void {
    const customInsightsComponent = this;
    $('#generateCustomInsightsModal').on('show.bs.modal', function (e) {
      customInsightsComponent.ngZone.run(() => customInsightsComponent.getSelectedCompanyIds());
    });
    $('#generateCustomInsightsModal').on('hide.bs.modal', function (e) {
      customInsightsComponent.ngZone.run(() =>
        customInsightsComponent.companyIds = []
      );
    });
  }

  getSelectedCompanyIds(): void {
      this.dataSharingService.currentSelectedCompanyIds.subscribe(
      companyIds => (this.companyIds = companyIds)
      );
  }

  requestCustomInsights(): void {
    this.companyService.requestCustomInsights(this.companyIds).subscribe(data => {
      if (data.success){
          this.pubMethods.showInfoMessage('Custom insights for this company have been requested and will be available in the next hour.');
          $('#generateCustomInsightsModal').modal('hide');
      }
    })
  }

}
