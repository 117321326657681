import { Component, Input } from '@angular/core';
import { CompanyService } from '../../services/company.service';

@Component({
  selector: 'ai-research-engine',
  templateUrl: './ai-research-engine.component.html',
  styleUrl: './ai-research-engine.component.css',
})
export class AiResearchEngineComponent {
  @Input() companyId: number | string = null;
  @Input() enableOpenQuestionChatBot: false;
  @Input() newUI: boolean = false;

  aiResearchAnswers: any;
  constructor(private companyService: CompanyService) {}
  ngAfterViewInit(): void {
    this.companyService
      .getAIResearchAssistantResponses(this.companyId)
      .subscribe(data => {
        this.aiResearchAnswers = data;
      });
  }
}
