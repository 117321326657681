import {Component, Input} from '@angular/core';
import {publicMethods} from '../../globals';
import {CompanyService} from '../../services/company.service';
import * as shape from 'd3-shape';


@Component({
  selector: 'corporate-structure-graph',
  templateUrl: './corporate-structure-graph.component.html',
  styleUrl: './corporate-structure-graph.component.css'
})
export class CorporateStructureGraphComponent {
  constructor(
    private pubMethods: publicMethods,
    private companyService: CompanyService
  ) {}

  curve = shape.curveLinear;
  isLoadingCorporateStructureGraph = false;
  @Input() companyNumber: any;
  @Input() companyName: any;
  corporateStructureGraph: any;


  ngOnInit() {
    this.getCorporateStructureGraph()
  }

  getCorporateStructureGraph() {
    this.isLoadingCorporateStructureGraph = true;
    this.companyService
      .getCorporateStructureGraph(this.companyNumber)
      .subscribe(graphInfo => {
        this.corporateStructureGraph = graphInfo['corporate_structure_graph'];
        this.isLoadingCorporateStructureGraph = false;
      }
    );
  }

  collapseCorporateStructureGraph(nodeId) {
    this.corporateStructureGraph['links'] = [];
    this.corporateStructureGraph['nodes'] = this.corporateStructureGraph['nodes'].filter(node => node['id'] === nodeId);
  }

  expandCorporateStructureUp(nodeId) {
    this.isLoadingCorporateStructureGraph = true;
    this.companyService
      .getExpandedCorporateStructure(<string>nodeId, 'ancestors')
      .subscribe(graphInfo =>
        this.addNodesAndLinksToCorporateStructure(graphInfo)
      );
  }

  expandCorporateStructureDown(nodeId) {
    this.isLoadingCorporateStructureGraph = true;
    this.companyService
      .getExpandedCorporateStructure(<string>nodeId, 'descendants')
      .subscribe(graphInfo =>
        this.addNodesAndLinksToCorporateStructure(graphInfo)
      );
  }

  addNodesAndLinksToCorporateStructure(graphInfo) {
    const graph = graphInfo['corporate_structure_graph'];
    if (graph == null) {
      this.isLoadingCorporateStructureGraph = false;
      return;
    }
    const currentLinkIds = this.corporateStructureGraph['links'].map(
      link => link.id
    );
    const currentNodeIds = this.corporateStructureGraph['nodes'].map(
      node => node.id
    );
    const newLinksToAdd = graph['links'].filter(
      link => currentLinkIds.indexOf(link['id']) === -1
    );
    const newNodesToAdd = graph['nodes'].filter(
      node => currentNodeIds.indexOf(node['id']) === -1
    );
    // We need to create a new instance of the array in order to trigger change detection in the component:
    this.corporateStructureGraph['links'] = this.corporateStructureGraph['links'].concat(newLinksToAdd);
    this.corporateStructureGraph['nodes'] = this.corporateStructureGraph['nodes'].concat(newNodesToAdd);
    this.isLoadingCorporateStructureGraph = false;
  }


}
