import { Component, Input, OnInit } from '@angular/core';
import { publicMethods } from '../../../globals';

@Component({
  selector: 'heat-score',
  templateUrl: './heat-score.component.html',
  styleUrls: ['./heat-score.component.css'],
})
export class HeatScoreComponent implements OnInit {
  @Input() heatScore: Record<string, any> = {};

  htmlElement = '';
  activities = '';
  numberOfChillies = 5;
  numberOfSolidIcons: number = null;
  heatIcons: Record<string, boolean>[] = [];

  heatColors = [
    '',
    'var(--zint-light-green)',
    'var(--zint-yellow-600)',
    'var(--zint-orange-600)',
    'var(--zint-red-600)',
    'var(--zint-red-900)',
  ];

  constructor(private pubMethod: publicMethods) {}

  ngOnInit(): void {
    this.getTooltip();
    this.updateHeatIcons();
  }

  getTooltip(): void {
    if (this.pubMethod.isEmptyObjectOrArray(this.heatScore)) {
      this.activities = 'Heat score activities';
      return;
    }
    this.activities = `${this.heatScore?.number_of_events} activities over ${this.heatScore.number_of_users} users in the last 30 days`;
  }

  updateHeatIcons(): void {
    const percentScore = this.heatScore?.percent_score;

    this.heatIcons = Array.from(
      { length: this.numberOfChillies },
      v => (v = { filled: false })
    );

    if (!percentScore) return;

    this.numberOfSolidIcons = Math.ceil(percentScore / 20); // 20% each

    this.heatIcons = this.heatIcons.map((_, index) => ({
      filled: index < this.numberOfSolidIcons,
    }));
  }
}
