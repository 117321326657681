import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'import-export-details',
  templateUrl: './import-export-details.component.html',
  styleUrls: ['./import-export-details.component.css'],
})
export class ImportExportDetailsComponent implements OnInit {
  @Input()
  inputParams: any = {
    detailedImportsOrExports: Array,
  };

  @Input() newUI: boolean = false;

  tooltipText =
    'By comparing to other organisations that have imported or exported similar products, we are able to calculate an approximate figure for the total monetary value of these imported or exported products in this month. Please note that this figure is an estimate.';
  constructor() {}

  ngOnInit() {}
}
