<div class="container-fluid">
        <div *ngIf="isLoadingCorporateStructureGraph" class="d-flex justify-content-center align-items-center h-100"><zint-loader size="xSmall"></zint-loader> <span class="ml-2">Loading Clusters</span></div>
        <div class="ngx-graph-holder" *ngIf="clustersGraphData">
            <div class="text-right">
                <button *ngIf="clustersGraphType === 'full'" class="btn btn-sm btn-outline-dark mb-1" (click)="reCentreGraph()" matTooltipPosition="above" matTooltip="Re-centre"><i class="fal fa-compress-arrows-alt"></i></button>
            </div>
            <ngx-graph class="chart-container" 
                [autoZoom]="false"
                [zoomLevel]="zoomLevel" 
                [panOnZoom]="true"
                [enableZoom]="true" 
                [zoomSpeed]="0.07" 
                [minZoomLevel]="0.1" 
                [maxZoomLevel]="2.0"
                [autoCenter]="centreGraph"                 
                [links]="setGraphLinks()"
                [nodes]="setGraphNodes()" 
                [clusters]="setGraphClusters()" 
                [layoutSettings]="{orientation: 'TB'}"
                layout="dagreCluster" 
                [showMiniMap]="clustersGraphType === 'full' && clustersGraphData?.nodes?.count > 10"
                miniMapPosition="UpperLeft"
                [miniMapMaxWidth]="400"
                [miniMapMaxHeight]="100"
                [draggingEnabled]="true">
                <ng-template #defsTemplate>
                    <svg:marker id="arrow" viewBox="0 -5 10 10" refX="8" refY="0" markerWidth="4" markerHeight="4" orient="auto" class="svg-arrow-fill">
                        <svg:path d="M0,-5L10,0L0,5" />
                    </svg:marker>
                </ng-template>        


                <ng-template #linkTemplate let-link>                    
                    <svg:g class="edge" ngx-tooltip [tooltipPlacement]="'top'" [tooltipType]="'tooltip'"
                        [tooltipTitle]="link.label">
                        <svg:path class="line svg-arrow-stroke" [attr.stroke-width]="link.line_width + 1"
                            stroke-opacity="0.8" marker-end="url(#arrow)">
                        </svg:path>
                    </svg:g>
                </ng-template>


                <ng-template #clusterTemplate let-cluster>                   
                    <svg:g class="cluster">   

                            <foreignObject *ngIf="cluster.labelText" [attr.x]="0"
                            [attr.y]="-50" [attr.height]="cluster.dimension.height" [attr.width]="cluster.dimension.width">
                                <div class="d-flex justify-content-center">
                                    <p class="cluster-label">{{cluster.labelText}}</p>
                                </div>
                            </foreignObject>
                        
                            <svg:rect                             
                            [attr.width]="cluster.dimension.width" 
                            [attr.height]="cluster.dimension.height" 
                            rx="50" ry="50"
                            [attr.fill]="cluster.data.color"
                            style="stroke-width:1px;"></svg:rect>
                    </svg:g>
                </ng-template>

                <ng-template #nodeTemplate let-node>
                    <svg:g class="cluster-box" [id]="'node-' + node.id">
                        <svg:rect [attr.width]="400" [attr.height]="node.dimension.height" rx="10" ry="10"
                            [attr.fill]="node.company_number === companyId ? '#fa9f42' : '#F5F4F8'"
                            style="stroke-width:1px; stroke: #dee2e6;" />
                        <foreignObject [id]="'fObj-'+ node.id" x="0.2em" [attr.width]="400" [attr.height]="node.dimension.height > 100 ? node.dimension.height : 100">
                            <div [id]="'content-' + node.id" class="content-container">
                                <i class="node-expand-btn fal fa-angle-double-down" 
                                    data-toggle="collapse"
                                    [attr.href]="'#collapse' + node.id" role="button" aria-expanded="false"
                                    [attr.aria-controls]="'collapse' + node.id"
                                    [style.background-color]="node.company_number === companyId ? '#fa9f42': '#F5F4F8'"
                                    [ngClass]="{'fa-rotate-180': expandedNodes.has(node.id)}"
                                    (click)="toggleNode(node.id)"
                                    ></i>
                
                                <div class="text-wrap node-flag-label">
                                    <span class="flag-icon flag-icon-{{node.country_code}}"></span>
                                    <a class="" [href]="node.link" target="_blank">
                                        {{node.label}} <i class="fal fa-external-link fa-xs"></i>
                                    </a>
                                </div>

                                <span *ngIf="node.cluster_member_reason?.heuristic_reason">Reason in cluster: {{node.cluster_member_reason.heuristic_reason}}</span>
                                <span *ngIf="node.cluster_member_reason?.distance_distance">Reason in cluster: {{generateClusterReasons(node.cluster_member_reason)}}</span>


                                <span *ngIf="node.revenue">Revenue: {{node.revenue |
                                    companyDataPipe:'Revenue':''}}</span>

                                <span *ngIf="node.sic_code_1" class="sic-description">{{node.sic_code_1}}</span>

                                <div class="collapse border-top pt-1 mb-3" [id]="'collapse' + node.id">                                    
                                    <div *ngIf="node.directors" class=" text-truncate" ngx-tooltip tooltipPlacement="top" tooltipType="tooltip"
                                        [tooltipTitle]="generateDirectorsList(node.directors)">Directors: {{ node.directors}}
                                    </div>
                                    <span *ngIf="node.postcode">Postcode: {{node.postcode}}</span>
                                    
                                </div>
                
                            </div>
                        </foreignObject>
                
                    </svg:g>
                </ng-template>
            </ngx-graph>
        </div>    
</div>

