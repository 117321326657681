
<div *ngIf="isLoadingCorporateStructureGraph" class="d-flex justify-content-center align-items-center h-100"><zint-loader size="xSmall"></zint-loader> <span class="ml-2">Loading Graph</span></div>

@if (corporateStructureGraph) {

    <div class="ngx-graph-holder">
        <ngx-graph #graph class="chart-container" [autoZoom]="true" [panOnZoom]="true"
            [enableZoom]="true" [zoomSpeed]="0.05" [minZoomLevel]="0.1" [maxZoomLevel]="2.0"
            [autoCenter]="true" [links]="corporateStructureGraph.links"
            [nodes]="corporateStructureGraph.nodes" [curve]="curve"
            [layoutSettings]="{orientation: 'TB'}">

            <ng-template #defsTemplate>
                <svg:marker id="arrow" viewBox="0 -5 10 10" refX="8" refY="0" markerWidth="4" class="svg-arrow-fill" markerHeight="4" orient="auto">
                    <svg:path d="M0,-5L10,0L0,5" />
                </svg:marker>
            </ng-template>

            <ng-template #nodeTemplate let-node>
                <svg:g class="node">
                    <svg:rect *ngIf="node.label == companyName" rx="10" ry="10"
                        style="stroke-width:1px; stroke: #dee2e6" [attr.width]="node.dimension.width"
                        [attr.height]="100" [attr.fill]="'#FA9F42'" />
                    <svg:rect *ngIf="node.label != companyName" rx="10" ry="10"
                        style="stroke-width:1px; stroke: #dee2e6" [attr.width]="node.dimension.width"
                        [attr.height]="100" [attr.fill]="'#f4f5f7'" />
                    <switch>
                        <foreignObject *ngIf="!node.link" x="0.1em" width="2em" height="1.8em">
                            <span class="flag-icon flag-icon-{{node.country_code}}"></span>
                        </foreignObject>
                        <foreignObject *ngIf="node.link" x="1.2em" width="2em" height="1.8em">
                            <span class="flag-icon flag-icon-{{node.country_code}}"></span>
                        </foreignObject>
                    </switch>
                    <svg:text *ngIf="!node.link" alignment-baseline="central" [attr.y]="10">
                        <tspan x="0.1em" dx="1.8em" dy="0.5em">{{node.label}}&nbsp;&nbsp;&nbsp;&nbsp;
                        </tspan>
                    </svg:text>
                    <switch *ngIf="node.link">
                        <foreignObject [attr.width]="node.dimension.width" [attr.height]="100">
                            <p style="right: 1px; position: absolute; bottom: -1.1em; cursor: pointer"
                                (click)="collapseCorporateStructureGraph(node.id)">
                                <i class="fal fa-compress-arrows-alt"></i>
                            </p>
                            <p style="right: 20px; position: absolute; bottom: -1.1em; cursor: pointer"
                                (click)="expandCorporateStructureUp(node.id)">
                                <i class="fal fa-caret-circle-up"></i>
                            </p>
                            <p style="right: 40px; position: absolute; bottom: -1.1em; cursor: pointer"
                                (click)="expandCorporateStructureDown(node.id)">
                                <i class="fal fa-caret-circle-down"></i>
                            </p>
                        </foreignObject>
                    </switch>
                    <svg:text *ngIf="node.link" alignment-baseline="central" [attr.x]="10"
                        [attr.y]="10">
                        <tspan x="1.2em" dx="1.8em" dy="0.5em">{{node.label}}&nbsp;&nbsp;&nbsp;&nbsp;
                        </tspan>
                        <tspan x="1.2em" dy="1.8em" *ngIf="node.revenue">Revenue: {{ node.revenue |
                            companyDataPipe:'Revenue':'' }}&nbsp;&nbsp;&nbsp;&nbsp;</tspan>
                        <tspan x="1.2em" dy="1.2em" *ngIf="node.sic_code_1">{{ node.sic_code_1
                            }}&nbsp;&nbsp;&nbsp;&nbsp;</tspan>
                        <tspan x="1.2em" dy="1.2em"></tspan>
                        <a attr.xlink:href="{{node.link}}" target="_blank" *ngIf="node.link">
                            <tspan x="1.2em" dy="1.2em">View Organisation</tspan>
                        </a>
                    </svg:text>
                </svg:g>
            </ng-template>

            <ng-template #linkTemplate let-link>
                <svg:g class="edge" ngx-tooltip [tooltipPlacement]="'top'" [tooltipType]="'tooltip'"
                    [tooltipTitle]="link.label">
                    <svg:path class="line svg-arrow-stroke" [attr.stroke-width]="link.line_width + 1" stroke-opacity="0.8" marker-end="url(#arrow)">
                    </svg:path>
                </svg:g>
            </ng-template>
        </ngx-graph>
    </div>

}
