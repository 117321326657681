import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommunicationService } from '../services/communication.service';
import { Router } from '@angular/router';
import { GlobalSearchService } from '../services/global-search.service';
import { AlertsService } from '../services/alerts.service';
import { CookieService } from 'ngx-cookie';
import { DataSharingService } from '../services/data-sharing.service';
import { ZintGrowService } from '../services/zint-grow.service';
import { publicMethods } from '../globals';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  accountEmail: any;
  accountId: number;
  isSuperUser: any;
  isParentOrgSuperUser: any;
  urlNoParams: string;
  companyNameSearch: '';
  shortlistedCompaniesByName: Array<any> = [];
  notifications: Array<any> = [];
  title = 'Zint';

  hasPropensityScoreAccess: any;
  hasSalesforceAccessMessage: any;
  hasSalesforceV2AccessMessage: any;
  hasHubspotAccess: boolean;
  hasDeepCrawlAccess: boolean;
  hasRecentAccountsAccess: boolean;
  hasMultiplePostcodeSearchAccess: boolean;
  hasCompanyMatcherAccess: boolean;
  hasCrossSellAccess: boolean;
  hasMarketMappingReportAccess: boolean;
  hasTerritoryAssignmentAccess: boolean;
  hasZintGrowAccess: boolean;
  hasCalendarIntegrationAccess: boolean;

  showCookieBanner = false;

  zgResultCountSubscription: Subscription;
  zgUnreadCount: number = 0;

  constructor(
    private communicationService: CommunicationService,
    private alertsService: AlertsService,
    public appComponentRouter: Router,
    private globalSearchService: GlobalSearchService,
    private cookieservice: CookieService,
    private pubMethod: publicMethods,
    private dataShareStore: DataSharingService,
    private zintGrowService: ZintGrowService
  ) {}

  isLoadingAlerts = this.alertsService.isLoading;
  isLoadingNameSearch = this.globalSearchService.isLoading;

  ngOnInit(): void {
    // If the current page is logout, then don't initialise anything for at least 3 seconds as all user data will be wiped shortly.
    // We will be redirected after about 1 second anyway.
    // This is to stop an inbetween state where we think we are dealing with the currently logged in user but we log out and the
    // user is then unauthenticated and ends up making a load of unauthenticated requests.
    if (window.location.pathname === '/logout') {
      setTimeout(() => {
        this.updateAllUserDetails();
      }, 5000);
    } else {
      this.updateAllUserDetails();
    }
    const zintCookiesAccepted = this.cookieservice.get('zintCookiesAccepted');
    if (!zintCookiesAccepted) {
      this.showCookieBanner = true;
    }
  }

  updateAllUserDetails(): void {
    // First get the user access levels; then send for e.g. Zint Grow unread count after that.
    this.getAllUserAccountData().then(() => {
      if (!this.accountId) return;
      this.getZGUnreadNotificationsCount();
      this.alertsService
        .getAlertNotifications()
        .subscribe(data => this.updateNotificationAlerts(data));
    });
  }

  getAllUserAccountData(): Promise<void> {
    return new Promise<void>(resolve => {
      this.communicationService.getEmailMessage().subscribe(accountEmail => {
        this.accountEmail = accountEmail;
      });
      this.communicationService.getUserAccountId().subscribe(accountId => {
        this.accountId = accountId;
      });
      this.communicationService
        .getIsSuperUserMessage()
        .subscribe(isSuperUser => {
          this.isSuperUser = isSuperUser;
        });
      this.communicationService
        .getIsParentOrgSuperUserMessage()
        .subscribe(isParentOrgSuperUser => {
          this.isParentOrgSuperUser = isParentOrgSuperUser;
        });

      this.communicationService
        .getHasPropensityScoreAccessMessage()
        .subscribe(hasPropensityScoreAccess => {
          this.hasPropensityScoreAccess = hasPropensityScoreAccess;
        });
      this.communicationService
        .getHasSalesforceAccessMessage()
        .subscribe(hasSalesforceAccessMessage => {
          this.hasSalesforceAccessMessage = hasSalesforceAccessMessage;
        });
      this.communicationService
        .getHasSalesforceV2AccessMessage()
        .subscribe(hasSalesforceV2AccessMessage => {
          this.hasSalesforceV2AccessMessage = hasSalesforceV2AccessMessage;
        });
      this.communicationService
        .getHubspotAccess()
        .subscribe(hasHubspotAccess => {
          this.hasHubspotAccess = hasHubspotAccess;
        });
      this.communicationService
        .getHasDeepCrawlAccessMessage()
        .subscribe(hasDeepCrawlAccess => {
          this.hasDeepCrawlAccess = hasDeepCrawlAccess;
        });
      this.communicationService
        .getHasRecentAccountsAccessMessage()
        .subscribe(hasRecentAccountsAccess => {
          this.hasRecentAccountsAccess = hasRecentAccountsAccess;
        });
      this.communicationService
        .getHasMultiplePostcodeSearchAccessMessage()
        .subscribe(hasMultiplePostcodeSearchAccess => {
          this.hasMultiplePostcodeSearchAccess =
            hasMultiplePostcodeSearchAccess;
        });
      this.communicationService
        .getHasCompanyMatcherAccessMessage()
        .subscribe(hasCompanyMatcherAccess => {
          this.hasCompanyMatcherAccess = hasCompanyMatcherAccess;
        });
      this.communicationService
        .getHasCrossSellAccessMessage()
        .subscribe(hasCrossSellAccess => {
          this.hasCrossSellAccess = hasCrossSellAccess;
        });
      this.communicationService
        .getHasMarketMappingReportAccessMessage()
        .subscribe(hasMarketMappingReportAccess => {
          this.hasMarketMappingReportAccess = hasMarketMappingReportAccess;
        });
      this.communicationService
        .getHasTerritoryAssignmentAccessMessage()
        .subscribe(hasTerritoryAssignmentAccess => {
          this.hasTerritoryAssignmentAccess = hasTerritoryAssignmentAccess;
        });
      this.communicationService
        .getHasCalendarIntegrationAccess()
        .subscribe(hasCalendarIntegrationAccess => {
          this.hasCalendarIntegrationAccess = hasCalendarIntegrationAccess;
        });
      this.communicationService
        .getHasZintGrowAccessMessage()
        .subscribe(hasZintGrowAccess => {
          this.hasZintGrowAccess = hasZintGrowAccess;
          resolve();
          // Note that we need to call resolve here to "trigger" the promise.
        });
    });
  }

  getZGUnreadNotificationsCount(): void {
    this.subscribeToCountChanges();
    const existingCookie = this.cookieservice.get('zgUnreadCountForUser');
    if (existingCookie) {
      const [cachedCount, userId] = JSON.parse(existingCookie).split('-');
      if (parseInt(userId) === this.accountId) {
        this.zgUnreadCount = parseInt(cachedCount);
        return;
      }
    }

    if (this.hasZintGrowAccess) {
      this.zintGrowService
        .getUnreadGrowNotificationsCount()
        .subscribe(({ count }) => {
          this.setCookieForUnreadCount(count);
          this.zgUnreadCount = count;
        });
    }
  }

  setCookieForUnreadCount(count: number): void {
    const twelveHourExpiry = new Date();
    twelveHourExpiry.setHours(new Date().getHours() + 12);

    // cookie example "13-2" where 13 is count and 2 is userId
    const countDetails = `${count}-${this.accountId}`;

    this.pubMethod.setCookieAndExpiry({
      name: 'zgUnreadCountForUser',
      value: countDetails,
      expiry: twelveHourExpiry,
    });
  }

  subscribeToCountChanges(): void {
    if (this.zgResultCountSubscription) return;

    this.zgResultCountSubscription =
      this.dataShareStore.currentZgResultCount.subscribe(newCount => {
        if (newCount !== this.zgUnreadCount) {
          this.zgUnreadCount = newCount;
          this.setCookieForUnreadCount(this.zgUnreadCount);
        }
      });
  }

  updateNotificationAlerts(data): void {
    this.notifications = data.notifications;
  }

  markAlertAsRead($event, alertId): void {
    $event.stopPropagation();
    this.alertsService
      .markAlertCompaniesAsRead(alertId)
      .subscribe(data => this.updateNotificationAlerts(data));
  }

  searchForCompanyByName(): void {
    this.globalSearchService
      .searchCompanyByName(this.companyNameSearch)
      .subscribe(data => (this.shortlistedCompaniesByName = data));
  }

  acceptCookies(): void {
    this.showCookieBanner = false;
    const oneYear = new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    );

    this.pubMethod.setCookieAndExpiry({
      expiry: oneYear,
      name: 'zintCookiesAccepted',
      value: 'true',
    });
  }

  ngOnDestroy() {
    if (this.zgResultCountSubscription) {
      this.zgResultCountSubscription.unsubscribe();
    }
  }
}
