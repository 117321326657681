import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { GlobalSearchService } from '../../services/global-search.service';
import { ZintGrowSubscriptionsService } from '../../services/zint-grow-subscriptions.service';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { AuthenticationService } from '../../services/authentication.service';
import { CommunicationService } from '../../services/communication.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { publicMethods } from '../../globals';
import { PermissionsService } from '../../services/permissions.service';
declare var $: any;

@Component({
  selector: 'zint-grow-subscriptions',
  templateUrl: './zint-grow-subscriptions.component.html',
  styleUrls: ['./zint-grow-subscriptions.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ZintGrowSubscriptionsComponent implements OnInit {
  isLoading;
  isSuperUser: false;
  currentUser: Record<string, any> = {};
  organisationTeams: any;
  organisationUsers: any;

  eventSourceTypes;
  ruleSets;
  selectedRuleSetIndex = 0;
  rulesetIndexToDelete;
  newRuleSetName;
  userConfigErrors: Record<string, any> = {};
  newRuleSetImportance;
  newRuleSetEmail = false;
  ruleCount: Record<string, any> = {};

  userOrganisationCustomColumns;
  selectedUserColumnIDForNewRuleSetSubscription;
  companyNameInput = '';
  shortlistedCompaniesByName: Array<any>;
  isEditingRuleSet: boolean = false;
  trackedCompanies: Array<any> = [];
  isSettingsStepCompleted: boolean | null = null;
  isNewOrganisationColumn: boolean = false;
  isColumnEditableByUser: boolean = false;

  viewTeamMembers: Record<string, any>[] = [];
  viewUsers: Record<string, any>[] = [];
  selectedTeamMemberId: number | null = null;
  totalRowsCount: number;
  nextPageUrl: string | null = null;
  hasMoreData: boolean = false;

  displayedColumns: string[] = ['userEmail', 'ukCompanyName', 'actions'];

  @ViewChild('configStepper') configStepper: MatStepper;
  @ViewChild('catStep') catStep: MatStep;
  @ViewChild('backgroundStep') backgroundStep: MatStep;
  @ViewChild('settingsStep') settingsStep: MatStep;
  @ViewChild('searchMenuTrigger') searchMenuTrigger: MatMenuTrigger;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private zintGrowSubscriptionsService: ZintGrowSubscriptionsService,
    private globalSearchService: GlobalSearchService,
    private communicationService: CommunicationService,
    private authService: AuthenticationService,
    private permissionsService: PermissionsService,
    private pubMethods: publicMethods,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.isLoading = this.zintGrowSubscriptionsService.isLoading;

    this.communicationService.getIsSuperUserMessage().subscribe(isSuperUser => {
      this.isSuperUser = isSuperUser;
      if (this.isSuperUser) {
        this.isColumnEditableByUser = true;
        this.permissionsService.getSuperuserTeamsAndUsers().subscribe(data => {
          this.organisationTeams = data.organisation_teams;
          this.organisationUsers = data.organisation_users;
        });
      }

      if (!this.isSuperUser) {
        this.authService.isUserAuthenticated().subscribe(data => {
          this.currentUser = data;
          this.selectedTeamMemberId = this.currentUser.user_profile_id;
        });
      }
    });

    // TODO validate that user is superuser.
    this.zintGrowSubscriptionsService.getEventSourceTypes().subscribe(data => {
      this.eventSourceTypes = data;
    });

    this.zintGrowSubscriptionsService.listRuleSets().subscribe(data => {
      if (data.rule_sets.length < 1) return;
      this.setRuleSets(data.rule_sets, true);
      this.getRulesCount();
    });

    this.zintGrowSubscriptionsService
      .getUserTypeCustomColumns()
      .subscribe(output => {
        this.userOrganisationCustomColumns = output;
      });
  }

  getViewTeamsAndUsers(): void {
    /** from ruleSets[selectedRulesetIndex] get view_teams & view_users
     * just need email and userId for users
     * when selectedRulesetIndex changes, update this
     * to get new teams and users for that rule
     */

    if (!this.isSuperUser) {
      this.viewUsers = [
        {
          userEmail: this.currentUser.email,
          userId: this.currentUser.user_profile_id,
        },
      ];
      return;
    }

    const ruleSet = this.ruleSets[this.selectedRuleSetIndex];

    if (
      !ruleSet.hasOwnProperty('view_teams') ||
      !ruleSet.hasOwnProperty('view_users')
    )
      return;

    if (this.pubMethods.isEmptyObjectOrArray(ruleSet.view_teams)) {
      this.viewTeamMembers = [];
    }

    if (this.pubMethods.isEmptyObjectOrArray(ruleSet.view_users)) {
      this.viewUsers = [];
    }

    this.viewTeamMembers = ruleSet.view_teams.map(team => {
      return {
        name: team.name,
        superUser: team.superuser_email,
        orgUsers: team.organisation_users.map(user => ({
          userEmail: user.email,
          userId: user.userId,
        })),
      };
    });

    this.viewUsers = ruleSet.view_users.map(user => ({
      userEmail: user.email,
      userId: user.userId,
    }));
  }

  getColumnEditAccess(): void {
    const orgColName =
      this.ruleSets[this.selectedRuleSetIndex].organisation_column_name;

    const currentColumn = this.userOrganisationCustomColumns.filter(
      obj => obj.name === orgColName
    )[0];

    this.isColumnEditableByUser = currentColumn?.can_edit;
  }

  addCustomUserColumn(value: string) {
    if (value === 'newColumn') {
      this._snackBar.open('The Column name will be auto generated.', 'X', {
        duration: 3000,
      });

      this.isNewOrganisationColumn = true;
      this.selectedUserColumnIDForNewRuleSetSubscription = null;
      return;
    }

    this.isNewOrganisationColumn = false;
  }

  getRulesCount() {
    const rules = this.selectedRuleSetIndex
      ? this.ruleSets[this.selectedRuleSetIndex].rules
      : this.ruleSets[0].rules;
    this.ruleCount = rules?.reduce((acc, rule) => {
      rule.event_source_type in acc
        ? acc[rule.event_source_type]++
        : (acc[rule.event_source_type] = 1);
      return acc;
    }, {});
  }

  newRule(newRule) {
    this.ruleSets[this.selectedRuleSetIndex].rules.push(newRule);
    this.zintGrowSubscriptionsService
      .updateRuleSet(this.ruleSets[this.selectedRuleSetIndex])
      .subscribe(data => (this.ruleSets[this.selectedRuleSetIndex] = data));
    this.getRulesCount();
  }

  deleteRule(id) {
    const index = this.ruleSets[this.selectedRuleSetIndex].rules.findIndex(
      rule => rule.id === id
    );
    this.ruleSets[this.selectedRuleSetIndex].rules.splice(index, 1);
    this.zintGrowSubscriptionsService
      .updateRuleSet(this.ruleSets[this.selectedRuleSetIndex])
      .subscribe(data => (this.ruleSets[this.selectedRuleSetIndex] = data));
    this.getRulesCount();
  }

  setRuleSets(data, firstLoad?) {
    this.ruleSets = data;
    if (firstLoad) {
      this.selectedRuleSetIndex = 0;
    }
  }

  createNewRulesetSubscription() {
    if (!this.newRuleSetName?.trim().length) {
      this.userConfigErrors.newRuleSetError = 'Please enter a valid input';
      return;
    } else if (!this.selectedUserColumnIDForNewRuleSetSubscription) {
      this.userConfigErrors.newRuleSetError =
        'Please select a column or add a new one';
      return;
    }

    const organisation_column_id = this.isNewOrganisationColumn
      ? null
      : Number(this.selectedUserColumnIDForNewRuleSetSubscription);

    const newRuleSet = {
      name: this.newRuleSetName,
      default_importance: Number(this.newRuleSetImportance) || 3,
      default_send_email: this.newRuleSetEmail,
      organisation_column_id,
      is_new_organisation_column: this.isNewOrganisationColumn,
    };

    this.zintGrowSubscriptionsService.createRuleSet(newRuleSet).subscribe(
      data => {
        const newRuleSetId = data.id;

        this.zintGrowSubscriptionsService.listRuleSets().subscribe(data => {
          this.setRuleSets(data.rule_sets);
          const index = this.ruleSets.findIndex(
            ruleSet => ruleSet.id === newRuleSetId
          );
          this.selectedRuleSetIndex = index;
          this.getRulesCount();
        });
        this.catStep.completed = true;
        this.configStepper.next();
      },
      error => {
        this._snackBar.open(error.message, 'OK', {
          duration: 3100,
          panelClass: 'error-snackbar',
        });
      }
    );

    this.userConfigErrors.newRuleSetError = '';
    this.newRuleSetName = '';
    this.newRuleSetImportance = '3';
  }

  stepToSettings(ruleSetIndex) {
    this.selectedRuleSetIndex = this.ruleSets.findIndex(
      ruleSet => ruleSet.id === ruleSetIndex.id
    );

    this.refreshTrackingTable();
    this.catStep.completed = true;
    if (this.ruleSets[this.selectedRuleSetIndex].about_us !== null) {
      this.backgroundStep.completed = true;
      this.configStepper.selectedIndex = 2;
    } else {
      this.backgroundStep.completed = false;
      this.configStepper.selectedIndex = 1;
    }
    this.getRulesCount();
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }

  toggleEditRuleSet() {
    this.isEditingRuleSet = !this.isEditingRuleSet;
    this.isSettingsStepCompleted = !this.isSettingsStepCompleted;
  }

  updateRuleSetNameAndImportance() {
    const ruleSet = this.ruleSets[this.selectedRuleSetIndex];
    if (!ruleSet.name?.trim().length) {
      this.settingsStep.completed = false;
      this.userConfigErrors.isUpdateValid = false;
      this._snackBar.open('Please enter a valid input', 'X', {
        duration: 2500,
      });
      return;
    }
    if (ruleSet.default_importance > 5 || ruleSet.default_importance < 1) {
      this.settingsStep.completed = false;
      this.userConfigErrors.isUpdateValid = false;
      this._snackBar.open('Importance should be 1 - 5', 'X', {
        duration: 2500,
      });
      return;
    }

    this.zintGrowSubscriptionsService.updateRuleSet(ruleSet).subscribe(data => {
      this.ruleSets[this.selectedRuleSetIndex] = data;
    });

    this.settingsStep.completed = true;
    this.userConfigErrors.isUpdateValid = true;
    this.isEditingRuleSet = false;
  }

  updateRuleSetAboutUs() {
    // TODO: better error handling here than !data.length?
    this.zintGrowSubscriptionsService
      .updateRuleSet(this.ruleSets[this.selectedRuleSetIndex])
      .subscribe(data => {
        if (data.length === 0) {
          this.backgroundStep.completed = false;
          return;
        }
        this.ruleSets[this.selectedRuleSetIndex] = data;
        this.backgroundStep.completed = true;
        this.configStepper.next();
      });
  }

  skipBackgroundStep() {
    this.backgroundStep.completed = true;
    this.configStepper.next();
  }

  deleteRuleset(index) {
    const id = this.ruleSets[index].id;
    this.zintGrowSubscriptionsService.deleteRuleSet(id).subscribe(() => {
      this.zintGrowSubscriptionsService.listRuleSets().subscribe(data => {
        this.setRuleSets(data.rule_sets, true);

        if (this.ruleSets.length === 0) {
          this.configStepper.selectedIndex = 0;
          return;
        }
        this.refreshTrackingTable();
      });
    });
    this.rulesetIndexToDelete = null;
    $('#confirmDeleteCategoryModal').modal('hide');
  }

  canStepAway(): boolean {
    if (this.ruleSets?.length < 1) return true;

    if (
      this.ruleSets?.length > 0 &&
      this.userConfigErrors?.isUpdateValid === false
    ) {
      return false;
    }

    return true;
  }

  refreshTrackingTable() {
    // code reference: https://v13.material.angular.io/components/table/examples#table-http
    // line by line breakdown in notion page https://www.notion.so/zint/Grow-Config-tracking-step-58682a807b08467381a8c1aaf112e6e3?pvs=4
    this.paginator.page
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.zintGrowSubscriptionsService
            .getUserColumnCompanies(
              this.ruleSets[this.selectedRuleSetIndex].organisation_column_id,
              this.paginator.pageIndex,
              this.isSuperUser
            )
            .pipe(catchError(() => of(null)));
        }),
        map(data => {
          if (data === null) return;
          this.totalRowsCount = data.count;
          return data.results;
        })
      )
      .subscribe(data => {
        if (!data) return;
        this.trackedCompanies = data;
      });
    this.getColumnEditAccess();
    this.getRulesCount();
    this.getViewTeamsAndUsers();
  }

  searchForCompanyByName(): void {
    this.globalSearchService
      .searchCompanyByName(this.companyNameInput)
      .subscribe(data => {
        this.searchMenuTrigger.openMenu();
        this.shortlistedCompaniesByName = data;
      });
  }

  addToTrackingTable(valueToMap) {
    if (!this.selectedTeamMemberId) {
      this._snackBar.open('Please select a team member', 'X', {
        duration: 1500,
      });
      return;
    }

    const alreadyTracked = this.trackedCompanies.find(obj => {
      return obj.ukCompanyName === valueToMap.company_name;
    });

    if (alreadyTracked) {
      this._snackBar.open('Already tracking this company', 'X', {
        duration: 1500,
      });
      return;
    }

    this.zintGrowSubscriptionsService
      .updateUserColumnCompany(
        this.ruleSets[this.selectedRuleSetIndex].organisation_column_id,
        valueToMap.company_id,
        Number(this.selectedTeamMemberId)
      )
      .subscribe(data => {
        this.trackedCompanies = [data, ...this.trackedCompanies];
        this.refreshTrackingTable();
      });
  }

  deleteTrackedCompany(tableRowId) {
    this.zintGrowSubscriptionsService
      .deleteUserColumnCompany(tableRowId)
      .subscribe(() => {
        this.refreshTrackingTable();
      });
  }

  renderEventSourceIcon(eventSourceType) {
    return this.pubMethods.renderEventSourceIcon(eventSourceType);
  }

  updateRuleSets(): void {
    this.zintGrowSubscriptionsService.listRuleSets().subscribe(data => {
      this.setRuleSets(data.rule_sets);
      this.refreshTrackingTable();
    });
  }
}
