export class Company {
  number: string;
  name: string;
  financials: any;
  checked: boolean = false;
  news: any;
  self_published_news: any;
}

export enum CompanyPageSectionEnums {
  CompanyOverView = 'company_overview',
  StrategicInsights = 'strategic_insights',
  CustomInsights = 'custom_insights',
  AIResearchAssistant = 'ai_research_assistant',
  AICompanyAssistant = 'ai_company_assistant',
  PitchBuilder = 'pitch_builder',
  KeyPeople = 'key_people',
  Website = 'website',
  Financials = 'financials',
  AssetBreakdown = 'asset_breakdown',
  GeographicalRevenue = 'geographical_revenue',
  LatestNews = 'latest_news',
  HiringFor = 'hiring_for',
  EventsAttending = 'events_attending',
  ImportsExports = 'imports_&_exports',
  ShareInformation = 'share_information',
  Grants = 'grants',
  IndustryPeerComparison = 'industry_peer_comparison',
  IndustryInsights = 'industry_insights',
  RecentLegislation = 'recent_legislation',
  LegalEntityIdentifier = 'legal_entity_identifier',
  Trademarks = 'trademarks',
  PatentsFiled = 'patents_filed',
  Sustainability = 'sustainability',
  GovernmentContractsAwarded = 'government_contracts_awarded',
  OnlineMarketplaces = 'online_marketplaces',
  CompanyCharges = 'company_charges',
  EnergyEmissions = 'energy_and_emissions',
  FunctionalDepartments = 'functional_departments',
  CorporateStructure = 'corporate_structure',
  CrossSellOpportunities = 'cross_sell_opportunities',
  CorporateOfficers = 'corporate_officers',
  ProbableSuppliers = 'probable_suppliers',
  SimilarOrganisations = 'similar_organisations',
}

export const allFinancialTerms = [
  'called_up_share_capital',
  'capital_and_reserves',
  'cash_at_bank',
  'corporation_tax',
  'cost_of_sales',
  'creditors',
  'creditors_after_one_year',
  'creditors_within_one_year',
  'current_assets',
  'debtors',
  'dividends',
  'fixed_assets',
  'gross_profit',
  'interest_payable',
  'net_assets',
  'other_debtors',
  'profit',
  'profit_before_tax',
  'retained_earnings',
  'revenue',
  'shareholders_funds',
  'tangible_assets',
  'tax',
  'total_assets_minus_current_liabilities',
  'trade_debtors',
  'wages_and_salaries',
  'share_premium',
  'net_assets_liabilities_including_pension_asset_liability',
  'net_current_assets_liabilities',
  'total_assets_less_current_liabilities',
  'cash_bank_in_hand',
  'debtors_due_within_one_year',
  'debtors_due_after_one_year',
  'other_debtors_within_one_year',
  'other_debtors_due_after_one_year',
  'tangible_fixed_assets',
  'tangible_fixed_assets_cost_or_valuation',
  'tangible_fixed_assets_depreciation',
  'called_up_share_capital_not_paid_not_expressed_as_current_asset',
  'stocks_inventor',
  'intangible_fixed_assets',
  'intangible_fixed_assets_cost_or_valuation',
  'intangible_fixed_assets_aggregate_amortisation_impairment',
  'investments_fixed_assets',
  'turnover_gross_operating_revenue',
  'other_operating_income',
  'capital_employed',
  'creditors_due_within_one_year',
  'creditors_due_after_one_year',
  'trade_creditors_after_one_year',
  'trade_creditors_within_one_year',
  'other_creditors_due_within_one_year',
  'other_creditors_after_one_year',
  'profit_loss_account_reserve',
  'provisions_for_liabilities_charge',
  'share_premium_account',
  'gross_profit_loss',
  'operating_profit_loss',
  'profit_loss_for_period',
  'staff_costs',
  'average_number_employees_during_period',
  'raw_materials_consumables',
  'submitted',
];
