import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { publicMethods, baseUrl } from 'globals';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class HubspotExporterService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = {
    loadingHubspotExportStatus: false,
  };

  getResultFromHubspot(companyIds): Observable<any> {
    const _params = new HttpParams().set(
      'companyIds',
      this.pubMethods.stringifyListForGetParams(companyIds)
    );
    this.isLoading['loadingHubspotExportStatus'] = true;
    return this.http
      .post(
        baseUrl + '/hubspot/run_hubspot_export_query/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingHubspotExportStatus'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getCorporateStructureGraphWithClusters',
            [],
            this.isLoading
          )
        )
      );
  }

  updateExistingHubspotRecords(
    hubspotRecordIdsToCompanyNumbers
  ): Observable<any> {
    const _params = new HttpParams().set(
      'hubspotRecordIdsToCompanyNumbers',
      this.pubMethods.stringifyDictForGetParams(
        hubspotRecordIdsToCompanyNumbers
      )
    );
    this.isLoading['updatingHubspotRecords'] = true;
    return this.http
      .post(
        baseUrl + '/hubspot/update_existing_hubspot_records/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['updatingHubspotRecords'] = false)),
        catchError(
          this.pubMethods.handleError(
            'updateExistingHubspotRecords',
            [],
            this.isLoading
          )
        )
      );
  }

  createNewHubspotRecords(companyNumbers): Observable<any> {
    const _params = new HttpParams().set(
      'companyNumbers',
      this.pubMethods.stringifyListForGetParams(companyNumbers)
    );
    this.isLoading['creatingHubspotRecords'] = true;
    return this.http
      .post(
        baseUrl + '/hubspot/create_new_hubspot_records/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['creatingHubspotRecords'] = false)),
        catchError(
          this.pubMethods.handleError(
            'createNewHubspotRecords',
            [],
            this.isLoading
          )
        )
      );
  }
}
