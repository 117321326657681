<div class="container-fluid" style="margin-top: -50px">
    <div class="row">
        <!-- reset and search params tags box -->
        <div class="order-1 col-12 small-margin-bottom">
            <div *ngIf="searchTags && searchTags.length > 0">
                <div class="card" style="border-radius: 1px">
                    <div class="card-body overflow-scroll">

                        <ng-container *ngIf="!showSavePropensityScoreForm">

                            <button type="button" class="basic-button-base button-dark"
                                (click)="searchForm.resetForm(); resetAllFiltersAndUpdateFilters()"
                                style="margin-right: 10px" id="parameter-reset-button"><i
                                    class="fal fa-redo-alt"></i>
                                Reset</button>

                            <button type="button" class="basic-button-base button-dark"
                                (click)="showSaveSearchForm = true" style="margin-right: 10px"
                                *ngIf="!showSaveSearchForm"><i class="fal fa-save"></i> Save
                                Search</button>
                            <div style="display: inline-block; margin-right: 10px" *ngIf="showSaveSearchForm">
                                <div class="input-group">
                                    <div class="input-group-prepend" *ngIf="isSuperUser">
                                        <label class="input-group-text"
                                            style="font-size: 10px; border-radius: 0;">
                                            <input type="checkbox" name="makeSavedSearchShared"
                                                value="false"
                                                [(ngModel)]="makeSavedSearchShared" />
                                            <span>&nbsp;Shared across org?</span>
                                        </label>
                                    </div>
                                    <input type="text" class="form-control" name="savedSearchName"
                                        [(ngModel)]="savedSearchName" placeholder="Choose a name">
                                    <div class="input-group-append">
                                        <button class="btn basic-button-base button-dark"
                                            type="button"
                                            (click)="saveSearch()">
                                            <i class="fal fa-save"
                                                *ngIf="!isLoading.loadingSaveUserSearch"></i> <i
                                                class="fal fa-spinner fa-spin"
                                                *ngIf="isLoading.loadingSaveUserSearch"></i> Save
                                        </button>
                                        <button class="btn basic-button-base button-dark"
                                            type="button"
                                            (click)="showSaveSearchForm = false; savedSearchName = ''">
                                            <i class="fal fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <button type="button" class="basic-button-base button-dark"
                                (click)="chooseToAllowCreateAlertForm()" style="margin-right: 10px"
                                *ngIf="!showCreateAlertForm">
                                <i class="fal fa-bell"></i> Create Alert
                            </button>
                            <div style="display: inline-block; width: 500px; margin-right: 10px"
                                *ngIf="showCreateAlertForm">
                                <div class="input-group">
                                    <input type="text" class="form-control" name="savedAlertName"
                                        [(ngModel)]="savedAlertName" style="min-width: 200px"
                                        placeholder="Choose a name">
                                    <select [(ngModel)]="selectedAlertFrequency"
                                        name="selectedAlertFrequency"
                                        class="custom-select">
                                        <option
                                            *ngFor="let alertFrequencyOption of alertFrequencyOptions"
                                            [ngValue]="alertFrequencyOption.identifier">
                                            {{alertFrequencyOption.description}}</option>
                                    </select>
                                    <select [(ngModel)]="teamMemberIdToCreateAlertFor"
                                        class="custom-select"
                                        style="max-width: 25%" *ngIf="isSuperUser && teamMembers">
                                        <option *ngFor="let teamMember of teamMembers"
                                            [ngValue]="teamMember.id">{{
                                            teamMember.email }}</option>
                                    </select>
                                    <div class="input-group-append">
                                        <button class="btn basic-button-base button-dark"
                                            type="button"
                                            (click)="createAlert()">
                                            <i class="fal fa-spinner fa-spin"
                                                *ngIf="isLoading.loadingCreateAlert"></i>
                                            Create
                                        </button>
                                        <button class="btn basic-button-base button-dark"
                                            type="button"
                                            (click)="showCreateAlertForm = false; savedAlertName = ''; selectedAlertFrequency = ''">
                                            <i class="fal fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container
                            *ngIf="hasPropensityScoreAccess && !showSavePropensityScoreForm && (isSuperUser || (editablePropensityModels && editablePropensityModels.length > 0))">
                            <button type="button" class="basic-button-base button-dark"
                                (click)="showSavePropensityScoreForm = true"
                                [style.borderRightWidth]="canEditTAM ? '0px' : '1px'"
                                [style.marginRight]="canEditTAM ? '0px' : '10px'"><i
                                    class="fal fa-star"></i> Add to
                                Propensity Model</button>
                            <button type="button" *ngIf="canEditTAM"
                                class="basic-button-base button-dark"
                                data-toggle="modal" data-target="#editPropensityModelTAM"
                                style="margin-right: 10px"><i
                                    class="fal fa-edit"></i> Edit TAM</button>
                        </ng-container>
                        <div style="display: inline-block; width: 600px; margin-right: 10px"
                            *ngIf="showSavePropensityScoreForm && (isSuperUser || (editablePropensityModels && editablePropensityModels.length > 0))">
                            <div class="input-group">
                                <div class="input-group-prepend"
                                    *ngIf="isSuperUser && (!propensityModels || propensityModels.length == 0)">
                                    <button class="btn basic-button-base button-dark" type="button"
                                        data-toggle="modal"
                                        data-target="#propensityScoreTAMDefinition"><i
                                            class="fal fa-plus-circle"></i>
                                        New Propensity Model</button>
                                </div>
                                <select *ngIf="propensityModels && propensityModels.length > 0"
                                    [(ngModel)]="selectedPropensityModelId"
                                    name="selectedPropensityModelId"
                                    (change)="checkIfAddingNewPropensityScoreModel($event.target.value)"
                                    class="custom-select">
                                    <option *ngFor="let propensityModel of editablePropensityModels"
                                        [ngValue]="propensityModel.id">{{propensityModel.name}}</option>
                                    <option *ngIf="isSuperUser"
                                        value="{{stringToTriggerNewPropensityModel}}">+ Add New
                                        Propensity Model</option>
                                </select>
                                <input type="text" class="form-control"
                                    name="propensityScoreComponentDescription"
                                    [(ngModel)]="propensityScoreComponentDescription"
                                    placeholder="Description"
                                    [attr.disabled]="!propensityModels || propensityModels.length == 0 ? 'disabled': null">
                                <input type="number"
                                    (keyup)="validatepropensityScoreWeightingValue()"
                                    class="form-control" name="propensityScoreWeighting"
                                    [(ngModel)]="propensityScoreWeighting" placeholder="Weighting"
                                    [attr.disabled]="!propensityModels || propensityModels.length == 0 ? 'disabled': null">
                                <div class="input-group-append">
                                    <button class="btn basic-button-base button-dark" type="button"
                                        (click)="savePropensityScoreComponent()"
                                        [attr.disabled]="!propensityModels || propensityModels.length == 0 ? 'disabled': null">
                                        <i class="fal fa-save"
                                            *ngIf="!isLoadingZintPropensityScore.loadingSavePropensityScoreComponent"></i>
                                        <i class="fal fa-spinner fa-spin"
                                            *ngIf="isLoadingZintPropensityScore.loadingSavePropensityScoreComponent"></i>
                                        Save
                                    </button>
                                    <button class="btn basic-button-base button-dark" type="button"
                                        (click)="showSavePropensityScoreForm = false; propensityScoreComponentDescription = ''">
                                        <i class="fal fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <button type="button" class="basic-button-base button-dark"
                            (click)="showTerritoryForm = true"
                            style="margin-right: 10px"
                            *ngIf="!showSaveSearchForm && hasTerritoryAssignmentAccessMessage && isSuperUser"><i
                                class="fal fa-map"></i> Assign Territory</button>
                        <div style="display: inline-block; margin-right: 10px"
                            *ngIf="showTerritoryForm">
                            <div class="input-group">
                                <input type="text" class="form-control" name="territoryName"
                                    [(ngModel)]="territoryName"
                                    placeholder="Choose a name">
                                <div class="input-group-append">
                                    <button class="btn basic-button-base button-dark" type="button"
                                        (click)="saveTerritory()">
                                        <i class="fal fa-save"
                                            *ngIf="!isLoading.loadingSaveTerritory"></i> <i
                                            class="fal fa-spinner fa-spin"
                                            *ngIf="isLoading.loadingSaveTerritory"></i>
                                        Save
                                    </button>
                                    <button class="btn basic-button-base button-dark" type="button"
                                        (click)="showTerritoryForm = false; territoryName = ''">
                                        <i class="fal fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <search-parameters
                            [inputParams]="{'searchTags': searchTags, 'enableClearing': true}"
                            (onClearSearchParam)="clearSearchParam($event[0])"></search-parameters>
                    </div>
                </div>
            </div>
        </div>

        <!-- company counts and page numbers box -->
        <div class="order-3 order-md-2"
            [ngClass]="{'col-md-8 offset-md-4': !this.isSidebarCollapsed, 'col-md-11 offset-md-1': this.isSidebarCollapsed}">
            <div class="row">
                <div class="col-md-6 ">
                    <div *ngIf="!showSelectedNumbersSection && !totalCompaniesSelected">
                        <zint-loader size="xSmall"
                            *ngIf="isLoading.loadingCompanyCount"></zint-loader>
                        <div *ngIf="companyCount && !isLoading.loadingCompanyCount">
                            <ng-container *ngIf="areTerritoriesEnforced">
                                <i class="fal fa-exclamation-triangle" style="display: inline" matTooltip="Your team superuser has restricted your searches by territory."></i>&nbsp;
                            </ng-container>
                            <span *ngIf="!companyCount.exact">Estimated</span>
                            {{ companyCount.count | number}}
                            <span *ngIf="companyCount.active_only">active</span> companies</div>
                    </div>
                    <div *ngIf="showSelectedNumbersSection || totalCompaniesSelected">
                        <button type="button"
                            class="basic-button-base button-dark control-buttons selected-companies-button"
                            data-html="true" matTooltip="Tag or untag these organisations"
                            data-toggle="modal"
                            data-target="#tagModal">
                            <i class="fal fa-tag"></i>
                        </button>
                        <button type="button"
                            class="basic-button-base button-dark control-buttons selected-companies-button"
                            data-html="true" matTooltip="Download these organisations"
                            data-toggle="modal"
                            data-target="#downloadModal">
                            <i class="fal fa-download"></i>
                        </button>
                        <button *ngIf="hasSalesforceAccessMessage && !hasSalesforceV2AccessMessage"
                            type="button"
                            class="basic-button-base button-dark control-buttons selected-companies-button"
                            data-html="true" matTooltip="Export these companies to Salesforce"
                            data-toggle="modal"
                            data-target="#salesforceModal">
                            <i class="fab fa-salesforce salesforce-icon"></i>
                        </button>
                        <button *ngIf="hasSalesforceV2AccessMessage && totalCompaniesSelected == 1"
                            type="button"
                            class="basic-button-base button-dark control-buttons selected-companies-button"
                            data-html="true" matTooltip="Export these companies to Salesforce"
                            data-toggle="modal"
                            data-target="#exportToSalesforceModal">
                            <i class="fab fa-salesforce salesforce-icon"></i>
                        </button>
                        <button *ngIf="hasHubspotAccess"
                            type="button"
                            class="basic-button-base button-dark control-buttons selected-companies-button"
                            data-html="true" matTooltip="Export these companies to Hubspot"
                            data-toggle="modal"
                            data-target="#exportToHubspotModal">
                            <i class="fab fa-hubspot"></i>
                        </button>
                        <button *ngIf="hasCustomInsightsAccess"
                            type="button"
                            class="basic-button-base button-dark control-buttons selected-companies-button"
                            data-html="true" matTooltip="Generate custom insights for these companies"
                            data-toggle="modal"
                            data-target="#generateCustomInsightsModal">
                            <i class="fal fa-microchip"></i>
                        </button>
                        <button type="button"
                            class="basic-button-base button-dark control-buttons selected-companies-button"
                            data-html="true"
                            matTooltip="Bulk edit attributes of these organisations"
                            data-toggle="modal" data-target="#customColumnEditModal">
                            <i class="fal fa-arrow-to-right"></i>
                        </button>
                        <button type="button"
                            class="basic-button-base button-dark control-buttons selected-companies-button"
                            data-html="true" matTooltip="Mark alerted companies as unread"
                            data-toggle="modal"
                            data-target="#markAlertedCompaniesAsReadModal">
                            <i class="fal fa-envelope-open"></i>
                        </button>
                        <div style="display: inline">
                            <span *ngIf="!hasSelectedTotalNumberMatchingFilter">{{
                                totalCompaniesSelected }}
                                selected</span>
                            <span *ngIf="hasSelectedTotalNumberMatchingFilter"><span
                                    *ngIf="!companyCount.exact">~</span>{{ companyCount.count |
                                number}} selected</span>
                            <button class="custom-button-a-link" (click)="hasSelectedAllCompaniesByQuery()"
                                *ngIf="companyCount.count != totalCompaniesSelected">Select all
                                <span *ngIf="!companyCount.exact">~</span>{{ companyCount.count |
                                number }}
                                instead?</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 " style="right: 14px; margin-bottom: 5px">
                    <div class="row justify-content-md-end align-items-baseline pl-4 pl-md-0">

                        <button type="button" id="edit-columns-button" data-toggle="modal"
                            data-target="#editColumnsModal" (click)="loadResultsView = true"
                            class="basic-button-base button-dark right-hand-control-buttons" matTooltipPosition="above"
                            matTooltip="Edit Columns">
                            <i class="fal fa-columns"></i>
                        </button>

                        <div>
                            <button class="basic-button-base button-dark right-hand-control-buttons"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapse-show-selected-view-options">
                                <i class="fal fa-eye"></i>
                            </button>
                            <div class="collapse" id="collapse-show-selected-view-options">
                                <select class="custom-select" name="selectedView"
                                    [(ngModel)]="selectedView"
                                    style="position: absolute; z-index: 1; width: 200px; height: 29px; padding-top: 0px; padding-bottom: 0px;">
                                    <option *ngFor="let individualView of individualViews"
                                        [ngValue]="individualView"
                                        [attr.selected]="individualView.is_default === true ? 'selected' : null">
                                        {{individualView.name}}</option>
                                    <option *ngFor="let organisationView of organisationViews"
                                        [ngValue]="organisationView"
                                        [attr.selected]="organisationView.is_default === true ? 'selected' : null">
                                        {{organisationView.name}}</option>
                                </select>
                            </div>
                        </div>

                        <button type="button" (click)="toggleFixedHeaders()"
                            id="toggle-freeze-headers-button"
                            class="basic-button-base button-dark right-hand-control-buttons"
                            [ngClass]="{'button-dark-selected': freezeHeadersIsActive}" matTooltipPosition="above"
                            matTooltip="Toggle fixed headers">
                            <i class="fal fa-th-list" [ngClass]="{'white-text': freezeHeadersIsActive}"></i>
                        </button>

                        <div class="col-md-auto col-1 pagination-button">
                            <button type="button" class="basic-button-base button-dark control-buttons"
                                [attr.disabled]="pageNumber == 1 || isLoading.loadingCompanies ? 'disabled': null"
                                [ngClass]="{'button-disabled': pageNumber == 1 || isLoading.loadingCompanies}"
                                (click)="previousPage()"><i class="fal fa-caret-left"></i></button>
                        </div>
                        <div class="col-md-auto col-1 pagination-button" *ngIf="maxPageNumber && pageNumber != 1">
                            <button type="button" class="basic-button-base button-dark control-buttons"
                                (click)="definedPage(1)" [attr.disabled]="isLoading.loadingCompanies ? 'disabled': null"
                                [ngClass]="{'button-disabled': isLoading.loadingCompanies}">1</button>
                        </div>
                        <div class="col-md-auto col-1 pagination-button" *ngIf="maxPageNumber && pageNumber - 1 > 1">
                            <button type="button" class="basic-button-base button-dark control-buttons"
                                disabled="disabled">...</button>
                        </div>
                        <div class="col-md-auto col-1 pagination-button">
                            <button type="button"
                                class="basic-button-base button-dark control-buttons button-dark-selected"
                                style="color:white !important" disabled="disabled">{{ pageNumber
                                }}</button>
                        </div>
                        <div class="col-md-auto col-1 pagination-button"
                            *ngIf="maxPageNumber && pageNumber != maxPageNumber">
                            <button type="button"
                                class="basic-button-base button-dark control-buttons"
                                (click)="definedPage(pageNumber + 1)"
                                [attr.disabled]="isLoading.loadingCompanies ? 'disabled': null"
                                [ngClass]="{'button-disabled': isLoading.loadingCompanies}">{{
                                pageNumber + 1
                                }}</button>
                        </div>
                        <div class="col-md-auto col-1 pagination-button"
                            *ngIf="maxPageNumber && maxPageNumber - pageNumber > 2">
                            <button type="button"
                                class="basic-button-base button-dark control-buttons"
                                disabled="disabled">...</button>
                        </div>
                        <div class="col-md-auto col-1 pagination-button"
                            *ngIf="maxPageNumber && pageNumber != maxPageNumber && pageNumber + 1 != maxPageNumber">
                            <button type="button"
                                class="basic-button-base button-dark control-buttons"
                                (click)="definedPage(maxPageNumber)"
                                [attr.disabled]="isLoading.loadingCompanies ? 'disabled': null"
                                [ngClass]="{'button-disabled': isLoading.loadingCompanies}">{{
                                maxPageNumber }}</button>
                        </div>
                        <div class="col-md-auto col-1 pagination-button">
                            <button type="button" class="basic-button-base button-dark control-buttons"
                                [attr.disabled]="pageNumber == maxPageNumber || isLoading.loadingCompanies ? 'disabled': null"
                                [ngClass]="{'button-disabled': pageNumber == maxPageNumber || isLoading.loadingCompanies}"
                                (click)="nextPage()"><i class="fal fa-caret-right"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-left: 0px; margin-bottom: 0px;">
                <div class="col-xs-12">
                    <div class="dummy-top-scrollbar-wrapper">
                        <div class="dummy-top-scrollbar">

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- filters column with tabs -->
        <div class="order-2 order-md-3 small-margin-bottom pr-4 side-panel-mt-neg"
            [ngClass]="{'col-md-4': !isSidebarCollapsed, 'col-md-1': isSidebarCollapsed, 'side-panel-mt-neg-xtra': searchTags && searchTags.length > 0}">

            <div class="sticky-top" style="z-index: 13;" (click)="expandOrCollapseSidebar()">
                <div id="collapse-button" class="d-flex align-items-center no-border-left">
                    <i class="fal fa-caret-left" *ngIf="!isSidebarCollapsed"></i>
                    <i class="fal fa-caret-right" *ngIf="isSidebarCollapsed"></i>
                </div>
            </div>

            <ul class="nav nav-tabs" role="tablist" id="nav-items-holder">
                <li class="nav-item">
                    <a class="nav-link rounded-0" [ngClass]="{'active': true}" role="tab" data-toggle="tab"
                        data-target="#filters-tab-content">Filters</a>
                </li>
                <li *ngIf="hasAISearchAccess" class="nav-item">
                    <a class="nav-link rounded-0" role="tab" data-toggle="tab" data-target="#ai-chat-agent"
                        (click)="expandSideBarIfRequired()">AI Search</a>
                </li>
            </ul>


            <div class="tab-content">

                <div class="row tab-pane active" id="filters-tab-content" role="tabpanel">
                    <div class="d-flex" [ngClass]="{'flex-column border-right': isSidebarCollapsed}">
                        <div
                            [ngClass]="availableTerritories && availableTerritories.length > 0 && !this.isSidebarCollapsed ? 'col-md-9 pr-md-0': 'col pr-1'">
                            <div class="tooltip-wrapper" [attr.data-toggle]="isReadOnlyUser ? 'tooltip': null"
                                [attr.data-placement]="isReadOnlyUser ? 'top': null"
                                [matTooltip]="isReadOnlyUser ? 'Your account is read only': null">
                                <button type="button"
                                    class="basic-button-base button-dark control-buttons text-truncate"
                                    (click)="searchCompaniesAfterFilters(true)"
                                    [attr.disabled]="isLoading.loadingCompanies || isReadOnlyUser ? 'disabled': null">
                                    <span *ngIf="!isSidebarCollapsed">SEARCH
                                        {{selectedTerritory?.name}}</span>
                                    <span *ngIf="isSidebarCollapsed"><i class="fal fa-search"></i></span>
                                </button>
                            </div>
                        </div>
                        <div [ngClass]="{'col-md-3 pl-md-0': !this.isSidebarCollapsed, 'col-md-12': this.isSidebarCollapsed}"
                            class="pr-1" *ngIf="availableTerritories && availableTerritories.length > 0">
                            <div class="dropdown" matTooltipPosition="above"
                                [matTooltip]="isReadOnlyUser ? 'Your account is read only': 'Search within your territories'"
                                id="search-territories-tooltip-element">
                                <button class="basic-button-base button-dark control-buttons"
                                    [ngClass]="{'no-border-left': !isSidebarCollapsed}" type="button"
                                    data-toggle="dropdown"
                                    [attr.disabled]="isLoading.loadingCompanies || isReadOnlyUser ? 'disabled': null">
                                    <span>
                                        <i class="fa fa-globe-africa"></i>
                                        <i class="fal fa-caret-down d-none d-lg-inline"></i>
                                    </span>
                                </button>
                                <div class="dropdown-menu" style="right:0 !important;">
                                    <span role="button" class="dropdown-item dropdown-wrap"
                                        (click)="searchCompaniesWithTerritoryFilter(availableTerritory)"
                                        *ngFor="let availableTerritory of availableTerritories">{{availableTerritory.name}}</span>
                                    <span role="button" class="dropdown-item"
                                        matTooltip="Default search without territories" *ngIf="selectedTerritory?.name"
                                        (click)="searchCompaniesWithTerritoryFilter(null)">Reset
                                        Territory</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion col pr-0" id="search-area" [ngClass]="{'loading-ui': isLoading.loadingUI}" ngForm #searchForm="ngForm"
                        (click)="expandSideBarIfRequired()">
                    
                        <div class="card">
                            <div class="card-header" id="status-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#status-search-collapse">
                                        <i class="fal fa-building" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Status</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="status-search-collapse" class="collapse" data-parent="#search-area">
                                <div class="card-body">
                                    Company name contains:
                                    <input type="text" class="form-control" name="companyNameContains" [(ngModel)]="companyNameContains"
                                        (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"><br>
                                    <div class="form-group select-table">
                                        <label for="options">Company Status
                                            Options:</label>
                                        <div *ngFor="let companyStatusOption of companyStatusOptions">
                                            <div *ngIf="companyStatusOption.level == 0 || companyStatusOption.shouldShow">
                                                <label>
                                                    <input type="checkbox" [attr.name]="'companyStatusOption' + companyStatusOption.name"
                                                        [ngModelOptions]="{standalone: true}" value="{{companyStatusOption.name}}"
                                                        [(ngModel)]="companyStatusOption.checked" />
                                                </label>
                                                <span (click)="expandOrCollapseItems(companyStatusOption.children, 'companyStatusOptions')"
                                                    [ngClass]="{'expandable-span': companyStatusOption.level==0}"><span
                                                        *ngIf="companyStatusOption.level==1">&nbsp;&nbsp;<i class="fal fa-caret-down"></i>
                                                    </span>
                                                    {{companyStatusOption.status_clean}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group select-table">
                                        <label for="options">Company Category
                                            Options:</label>
                                        <div *ngFor="let companyCategoryOption of companyCategoryOptions">
                                            <div *ngIf="companyCategoryOption.level == 0 || companyCategoryOption.shouldShow">
                                                <label>
                                                    <input type="checkbox"
                                                        [attr.name]="'companyCategoryOption' + companyCategoryOption.name"
                                                        [ngModelOptions]="{standalone: true}" value="{{companyCategoryOption.name}}"
                                                        [(ngModel)]="companyCategoryOption.checked" />
                                                </label>
                                                <span
                                                    (click)="expandOrCollapseItems(companyCategoryOption.children, 'companyCategoryOptions')"
                                                    [ngClass]="{'expandable-span': companyCategoryOption.level==0}"><span
                                                        *ngIf="companyCategoryOption.level==1">&nbsp;&nbsp;<i class="fal fa-caret-down"></i>
                                                    </span>
                                                    {{companyCategoryOption.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    Company Age:<br>
                                    <zint-ngx-slider *ngIf="companyAgeRange" #companyAgeSliderElement [min]="companyAgeRange.at(0)"
                                        [max]="companyAgeRange.at(-1)" postfix="years" maxPostfix="+"
                                        (sliderChange)="updateCompanyAgeRange($event)"></zint-ngx-slider>
                    
                                </div>
                            </div>
                        </div>
                    
                        <div class="card">
                            <div class="card-header" id="industry-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#industry-search-collapse">
                                        <i class="fal fa-industry-alt" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Industry</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="industry-search-collapse" class="collapse" data-parent="#search-area">
                                <div class="card-body">
                                    <p>Company SIC Codes:</p>
                                    <div class="form-group overflowing-table select-table" id="sics-overflowing-table">
                    
                                        <div class="input-group xsmall-margin-top" style="padding-right: 10px; padding-bottom: 10px">
                                            <div class="input-group-prepend">
                                                <button class="btn basic-button-base button-dark" type="button" (click)="resetSicSearch()">
                                                    <i class="fal fa-redo-alt"></i>
                                                    Reset
                                                </button>
                                            </div>
                                            <input type="text" class="form-control" name="sicCodeTextSearch" [(ngModel)]="sicCodeTextSearch"
                                                (ngModelChange)="searchSicCodes()" placeholder="Search...">
                                        </div>
                                        <div *ngFor="let sicCodeChoice of companySICCodeOptions">
                                            <div *ngIf="(sicCodeChoice.type == 'section' && !isSearchingSicCodes) || sicCodeChoice.shouldShow"
                                                class="cut-off">

                                                <label class="expandable-label" matTooltip="Include this SIC code">
                                                  <i [attr.name]="'sicCodeChoiceSelect' + sicCodeChoice.identifier"
                                                     class="fa-square-check mr-1"
                                                     [ngClass]="{'fa-regular': sicCodeChoice.checked, 'fa-thin': !sicCodeChoice.checked}"
                                                     (click)="sicCodeChoice.checked = !sicCodeChoice.checked">
                                                  </i>
                                                </label>
                                                <label class="expandable-label" matTooltip="Exclude this SIC code">
                                                  <i [attr.name]="'sicCodeChoiceExclude' + sicCodeChoice.identifier"
                                                     class="fa-square-xmark"
                                                     [ngClass]="{'fa-regular': sicCodeChoice.excluded, 'fa-thin': !sicCodeChoice.excluded}"
                                                     (click)="sicCodeChoice.excluded = !sicCodeChoice.excluded">
                                                  </i>
                                                </label>

                                                <span (click)="expandOrCollapseSICCodes(sicCodeChoice.id)"
                                                    class="expandable-span"><ng-container
                                                        *ngFor="let dummy of ' '.repeat(sicCodeChoice.level).split(' ')">&nbsp;&nbsp;</ng-container>
                                                    <i class="fal fa-caret-down" *ngIf="sicCodeChoice.level > 0"></i>
                                                    {{sicCodeChoice.identifier}}
                                                    {{sicCodeChoice.description}}</span>
                                                <hr class="select-break-hr">
                                            </div>
                                        </div>
                                    </div>
                    
                                    <br>
                                    Homepage Website Text Search:
                                    <p style="font-size: 10px">Use NOT for negative
                                        search, AND for multiple phrases, OR for
                                        different options, and inverted commas (") for
                                        exact phrases. Each term taken as OR by
                                        default.</p>
                                    <input type="text" class="form-control" name="homepageSearchTerm" [(ngModel)]="homepageSearchTerm"
                                        (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"><br>
                    
                                    <div [ngClass]="{'hide-deep-crawl': !hasDeepCrawlAccess}">
                                        Zint Deep Crawl <sup><i class="fal fa-bolt" matTooltipPosition="above"
                                                matTooltip="This is a Zint Power Tool"></i></sup>
                                        Website Text Search:
                                        <p style="font-size: 10px" *ngIf="hasDeepCrawlAccess">Use NOT for
                                            negative search, AND
                                            for multiple phrases, OR for different
                                            options, and inverted commas (") for exact
                                            phrases. Each term taken as OR by
                                            default.</p>
                                        <p style="font-size: 10px" *ngIf="!hasDeepCrawlAccess"><i class="fal fa-ban"></i> Zint
                                            Deep Crawl allows you to search company
                                            websites beyond just the homepage. You do
                                            not have access to this on your plan. Please
                                            <a href="mailto:sales@zint.io?Subject=Zint%20Deep%20Crawl%20Enquiry">contact
                                                us</a>
                                            to upgrade.
                                        </p>
                                        <input type="text" class="form-control" [attr.disabled]="!hasDeepCrawlAccess ? 'disabled': null"
                                            name="fullWebsiteSearchTerm" [(ngModel)]="fullWebsiteSearchTerm"
                                            (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"><br>
                                    </div>
                    
                                    <div class="website-keyword-section">
                                        <div class="dropdown">
                                            Website Keywords
                                            <div *ngIf="websiteKeywords && websiteKeywords.length > 0">
                                                <span *ngFor="let websiteKeyword of websiteKeywords" class="badge"
                                                    style="background-color: #000a36; color: white; margin-right: 7px; margin-bottom: 8px">
                                                    {{ websiteKeyword }} <i class="fal fa-times tag-delete-button cursor-pointer"
                                                        (click)="removeWebsiteKeyword(websiteKeyword)"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control postcode-radius-text-box dropdown-toggle"
                                                name="websiteKeywordInput" placeholder="e.g. cryptocurrency" data-toggle="dropdown"
                                                [(ngModel)]="websiteKeywordInput" (keyup.enter)="forceAddWebsiteKeyword($event)"
                                                (ngModelChange)="websiteKeywordChanged()">
                                            <div class="dropdown-menu">
                                                <div class="d-flex justify-content-center align-items-center">
                                                    <zint-loader size="xSmall" *ngIf="isLoading.loadingWebsiteKeywords"></zint-loader>
                                                </div>
                                                <span *ngFor="let possibleWebsiteKeyword of possibleWebsiteKeywords"
                                                    class="dropdown-item cursor-pointer"
                                                    (click)="addWebsiteKeyword(possibleWebsiteKeyword)">
                                                    <span>{{ possibleWebsiteKeyword
                                                        }}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                    
                                </div>
                            </div>
                        </div>
                    
                        <div class="card">
                            <div class="card-header" id="geography-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#geography-search-collapse">
                                        <i class="fal fa-flag" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Geography</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="geography-search-collapse" class="collapse" data-parent="#search-area">
                                <div class="card-body">
                                    Address type:
                                    <select [(ngModel)]="selectedAddressTypeOption" class="custom-select" name="selectedAddressTypeOption"
                                        style="width: 100px">
                                        <option *ngFor="let addressOption of addressOptions" [ngValue]="addressOption">
                                            {{addressOption}}</option>
                                    </select>
                    
                                    <div class="row xsmall-margin-top">
                                        <div class="col"
                                            [ngClass]="{'transparent-text': geographyWithinRadiusOfPostcodeIsEditing || geographyWithinRadiusOfTownIsEditing}">
                                            Postcode begins with:
                                            <input type="text" class="form-control postcode-radius-text-box" name="postcodeBeginsWith"
                                                placeholder="e.g. EC2R 8" [(ngModel)]="postcodeBeginsWith"
                                                (ngModelChange)="postcodeBeginsWithChanged()"
                                                (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"
                                                [attr.disabled]="geographyPostcodeDistrictIsEditing || geographyWithinRadiusOfPostcodeIsEditing || geographyWithinRadiusOfTownIsEditing ? 'disabled': null"
                                                (focus)="geographyPostcodeBeginsWithIsEditing = true; postcodeDistrict = ''; postcodeWithinRadius = ''; townName = ''; selectedTownId = ''; ukRegion = null;"
                                                (focusout)="geographyPostcodeBeginsWithIsEditing = false">
                                        </div>
                                    </div>
                    
                                    <div class="row xsmall-margin-top">
                                        <div class="col"
                                            [ngClass]="{'transparent-text': geographyWithinRadiusOfPostcodeIsEditing || geographyWithinRadiusOfTownIsEditing}">
                                            Postcode district:
                                            <input type="text" class="form-control postcode-radius-text-box" name="postcodeDistrict"
                                                placeholder="e.g. EC2R" [(ngModel)]="postcodeDistrict"
                                                (ngModelChange)="postcodeDistrictChanged()"
                                                (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"
                                                [attr.disabled]="geographyPostcodeBeginsWithIsEditing || geographyWithinRadiusOfPostcodeIsEditing || geographyWithinRadiusOfTownIsEditing ? 'disabled': null"
                                                (focus)="geographyPostcodeDistrictIsEditing = true; postcodeBeginsWith = ''; postcodeWithinRadius = ''; townName = ''; selectedTownId = ''; ukRegion = null;"
                                                (focusout)="geographyPostcodeDistrictIsEditing = false">
                                        </div>
                                    </div>
                    
                                    <div class="row xsmall-margin-top">
                                        <div class="col">
                                            UK Region:
                                            <select [(ngModel)]="ukRegion" name="ukRegion" class="custom-select"
                                                (change)="ukRegionChanged()"
                                                [attr.disabled]="geographyPostcodeDistrictIsEditing || geographyPostcodeBeginsWithIsEditing || geographyWithinRadiusOfPostcodeIsEditing || geographyWithinRadiusOfTownIsEditing ? 'disabled': null"
                                                (focus)="postcodeDistrict = ''; postcodeWithinRadius = ''; postcodeBeginsWith = ''; townName = '';">
                                                <option *ngFor="let region of ukRegions" [ngValue]="region">{{region}}</option>
                                            </select>
                                        </div>
                                    </div>
                    
                                    <div class="row">
                    
                                        <div class="col">
                                            <div class="row xsmall-margin-top">
                                                <div class="col-md-6"
                                                    [ngClass]="{'transparent-text': geographyPostcodeBeginsWithIsEditing || geographyWithinRadiusOfTownIsEditing}">
                                                    Within radius of postcode:
                                                    <input type="text" class="form-control postcode-radius-text-box"
                                                        name="postcodeWithinRadius" placeholder="e.g. SW1A 1AA"
                                                        [(ngModel)]="postcodeWithinRadius" (ngModelChange)="postcodeWithinRadiusChanged()"
                                                        (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"
                                                        [attr.disabled]="geographyPostcodeDistrictIsEditing || geographyPostcodeBeginsWithIsEditing || geographyWithinRadiusOfTownIsEditing ? 'disabled': null"
                                                        (focus)="geographyWithinRadiusOfPostcodeIsEditing = true; postcodeDistrict = ''; postcodeBeginsWith = ''; townName = ''; selectedTownId = ''; ukRegion = null;"
                                                        (focusout)="geographyWithinRadiusOfPostcodeIsEditing = false">
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="dropdown"
                                                        [ngClass]="{'transparent-text': geographyPostcodeBeginsWithIsEditing || geographyWithinRadiusOfPostcodeIsEditing}">
                                                        Within radius of town name:
                                                        <input type="text" class="form-control postcode-radius-text-box dropdown-toggle"
                                                            name="townName" placeholder="e.g. Manchester" data-toggle="dropdown"
                                                            [(ngModel)]="townName" (ngModelChange)="townNameChanged()"
                                                            [attr.disabled]="geographyPostcodeDistrictIsEditing || geographyPostcodeBeginsWithIsEditing || geographyWithinRadiusOfPostcodeIsEditing ? 'disabled': null"
                                                            (focus)="geographyWithinRadiusOfTownIsEditing = true; postcodeDistrict = ''; postcodeWithinRadius = ''; postcodeBeginsWith = ''; ukRegion = null;"
                                                            (focusout)="geographyWithinRadiusOfTownIsEditing = false">
                                                        <i class="fal fa-check-square"
                                                            style="position: absolute; right: 10px; margin-top: -27px"
                                                            *ngIf="selectedTownId"></i>
                                                        <div class="dropdown-menu">
                                                            <span *ngFor="let possibleTownName of possibleTownNames"
                                                                class="dropdown-item cursor-pointer"
                                                                (click)="selectShortlistedTownName(possibleTownName._id, possibleTownName.name)">
                                                                <span>{{
                                                                    possibleTownName.name
                                                                    }}<span *ngIf="possibleTownName.county">,
                                                                        {{
                                                                        possibleTownName.county
                                                                        }}</span>, {{
                                                                    possibleTownName.country
                                                                    }}</span>
                                                            </span>
                                                        </div>
                                                    </div>
                    
                                                </div>
                                                <div class="col-md-12 xsmall-margin-top">
                                                    <zint-ngx-slider #mileRadiusSliderElement *ngIf="mileRadiusRegisteredAddressRange"
                                                        [isRange]="false" postfix="miles" [min]="mileRadiusRegisteredAddressRange.at(0)"
                                                        [max]="mileRadiusRegisteredAddressRange.at(-1)"
                                                        (sliderChange)="updatePostcodeMileageRange($event)"></zint-ngx-slider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                    
                                    <br>
                                    <label>
                                        <input type="checkbox" name="hasOverseasParent" value="false" [(ngModel)]="hasOverseasParent" />
                                        Has overseas parent?
                                    </label>
                    
                                    <br>
                                    <label>
                                        <input type="checkbox" name="hasOfficesOverseas" value="false" [(ngModel)]="hasOfficesOverseas" />
                                        Has office overseas?
                                    </label>
                    
                                    <br><br>
                    
                                    <div class="row">
                                        <div class="col-md">
                                            Has Offices in:
                                            <div class="form-group overflowing-table select-table">
                                                <div *ngFor="let countriesOfOfficesOption of countriesOfOfficesOptions">
                                                    <label>
                                                        <input type="checkbox"
                                                            [attr.name]="'countriesOfOfficesOption' + countriesOfOfficesOption.code"
                                                            [ngModelOptions]="{standalone: true}" value="{{countriesOfOfficesOption.code}}"
                                                            [(ngModel)]="countriesOfOfficesOption.checked" />
                                                        {{ countriesOfOfficesOption.name
                                                        }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            Country of Origin:
                                            <div class="form-group  overflowing-table select-table">
                                                <div *ngFor="let countriesOfOriginOption of countriesOfOriginOptions">
                                                    <label>
                                                        <input type="checkbox"
                                                            [attr.name]="'countriesOfOriginOption' + countriesOfOriginOption.code"
                                                            [ngModelOptions]="{standalone: true}" value="{{countriesOfOriginOption.code}}"
                                                            [(ngModel)]="countriesOfOriginOption.checked" />
                                                        {{ countriesOfOriginOption.name
                                                        }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                    
                                    <br>
                    
                                    Number of known office locations:
                                    <zint-ngx-slider #numberOfKnownOfficeLocationsSliderElement *ngIf="numberOfKnownOfficeLocationsRange"
                                        maxPostfix="+" [min]="numberOfKnownOfficeLocationsRange.at(0)"
                                        [max]="numberOfKnownOfficeLocationsRange.at(-1)"
                                        (sliderChange)="updateNumberOfKnownOfficeLocationsRange($event)"></zint-ngx-slider>
                    
                                    <br>
                    
                                    <div class="row">
                                        <div class="col-md">
                                            Overseas Revenue:
                                            <zint-ngx-slider #geographicalRevenueSliderElement *ngIf="geographicalRevenueOptions"
                                                [min]="geographicalRevenueOptions.at(0)" [max]="geographicalRevenueOptions.at(-1)"
                                                prefix="£"
                                                (sliderChange)="updateGeographicalRevenueRangeSelection($event)"></zint-ngx-slider>
                    
                                            <div class="input-group xsmall-margin-top" style="margin-bottom: -1px;">
                                                <div class="input-group-prepend">
                                                    <button class="btn basic-button-base button-dark" type="button"
                                                        (click)="resetGeographicalRevenueSearch()">
                                                        <i class="fal fa-redo-alt"></i>
                                                        Reset
                                                    </button>
                                                </div>
                                                <input type="text" class="form-control" style="border: solid 1px black"
                                                    name="geographicalRevenueTextSearch" [(ngModel)]="geographicalRevenueTextSearch"
                                                    (ngModelChange)="searchGeographicalRevenue()" placeholder="Search...">
                                            </div>
                                            <div class="overflowing-table select-table" style="margin-bottom: -1px;">
                                                <div
                                                    *ngFor="let territoryOfGeographicalRevenueOption of territoriesOfGeographicalRevenueOptions">
                                                    <ng-container *ngIf="territoryOfGeographicalRevenueOption.shouldShow">
                                                        <label>
                                                            <input type="checkbox"
                                                                [attr.name]="'territoryOfGeographicalRevenueOption' + territoryOfGeographicalRevenueOption.code"
                                                                [ngModelOptions]="{standalone: true}"
                                                                value="{{territoryOfGeographicalRevenueOption.code}}"
                                                                [(ngModel)]="territoryOfGeographicalRevenueOption.checked" />
                                                            {{
                                                            territoryOfGeographicalRevenueOption.name
                                                            }}
                                                        </label>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="form-group overflowing-table select-table">
                                                <div
                                                    *ngFor="let countryOfGeographicalRevenueOption of countriesOfGeographicalRevenueOptions">
                                                    <ng-container *ngIf="countryOfGeographicalRevenueOption.shouldShow">
                                                        <label>
                                                            <input type="checkbox"
                                                                [attr.name]="'countryOfGeographicalRevenueOption' + countryOfGeographicalRevenueOption.code"
                                                                [ngModelOptions]="{standalone: true}"
                                                                value="{{countryOfGeographicalRevenueOption.code}}"
                                                                [(ngModel)]="countryOfGeographicalRevenueOption.checked" />
                                                            {{
                                                            countryOfGeographicalRevenueOption.name
                                                            }}
                                                        </label>
                                                    </ng-container>
                                                </div>
                                                <hr>
                                            </div>
                                        </div>
                                    </div>
                    
                                </div>
                            </div>
                        </div>
                    
                        <div class="card">
                            <div class="card-header" id="structure-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#structure-search-collapse">
                                        <i class="fal fa-sitemap" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Structure</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="structure-search-collapse" class="collapse" data-parent="#search-area">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>
                                                <input type="checkbox" name="corporateStructureIsTopLevelParentCompany" value="false"
                                                    [(ngModel)]="corporateStructureIsTopLevelParentCompany" />
                                                Is top-level parent
                                            </label>
                                            <br>
                                            <label>
                                                <input type="checkbox" name="corporateStructureHasParent" value="false"
                                                    [(ngModel)]="corporateStructureHasParent" />
                                                Has parent
                                            </label>
                                            <br>
                                            <label>
                                                <input type="checkbox" name="corporateStructureDoesNotHaveParent" value="false"
                                                    [(ngModel)]="corporateStructureDoesNotHaveParent" />
                                                Does not have parent
                                            </label>
                                            <br>
                                            <label>
                                                <input type="checkbox" name="corporateStructureHasChild" value="false"
                                                    [(ngModel)]="corporateStructureHasChild" />
                                                Has child
                                            </label>
                                            <br>
                                            <label>
                                                <input type="checkbox" name="corporateStructureDoesNotHaveChild" value="false"
                                                    [(ngModel)]="corporateStructureDoesNotHaveChild" />
                                                Does not have child
                                            </label>
                    
                                            <br><br>
                    
                                            Number of Ultimate Beneficial Owners:<br>
                                            <zint-ngx-slider #numberUBOsSliderElement *ngIf="numberUBOsRange" [min]="numberUBOsRange.at(0)"
                                                [max]="numberUBOsRange.at(-1)" maxPostfix="+"
                                                (sliderChange)="updateNumberUBOsRange($event)"></zint-ngx-slider>
                                        </div>
                    
                                        <div class="col-md-6">
                                            Countries of Ultimate Beneficial Owner:
                                            <div class="form-group overflowing-table select-table">
                                                <div *ngFor="let countriesOfUBOsOption of countriesOfUBOsOptions">
                                                    <label>
                                                        <input type="checkbox"
                                                            [attr.name]="'countriesOfUBOsOption' + countriesOfUBOsOption.code"
                                                            [ngModelOptions]="{standalone: true}" value="{{countriesOfUBOsOption.code}}"
                                                            [(ngModel)]="countriesOfUBOsOption.checked" />
                                                        {{ countriesOfUBOsOption.name }}
                                                    </label>
                                                </div>
                                            </div>
                    
                                        </div>
                    
                                    </div>
                                    <div class="row">
                                        <div class="col-md">
                                            <label for="corporateStructureRelatedCompanies" class="mb-0">List all companies in
                                                group</label>
                                            <input name="corporateStructureRelatedCompanies" id="corporateStructureRelatedCompanies"
                                                type="text" placeholder="Company Number" class="form-control mb-1"
                                                [(ngModel)]="corporateStructureRelatedCompanies"
                                                (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div class="card">
                            <div class="card-header" id="financials-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#financials-search-collapse">
                                        <i class="fal fa-chart-bar" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Financials</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="financials-search-collapse" class="collapse" data-parent="#search-area">
                                <div class="card-body">
                    
                                    Revenue:<br>
                                    <label>
                                        <input type="checkbox" name="ignoreRevenueEstimates" value="false"
                                            [(ngModel)]="ignoreRevenueEstimates" />
                                        Ignore estimated revenue figures?
                                    </label>
                    
                                    <br>
                                    <zint-ngx-slider #revenueSliderElement *ngIf="revenueOptions" prefix="£" [min]="revenueOptions.at(0)"
                                        [max]="revenueOptions.at(-1)" [preDefinedSteps]="revenueOptions"
                                        (sliderChange)="updateRevenueRangeSelection($event)">
                                    </zint-ngx-slider>
                    
                                    <br>
                                    Net Profit:<br>
                    
                                    <zint-ngx-slider #netProfitSliderElement *ngIf="netProfitOptions" prefix="£" maxPostfix="+"
                                        [min]="netProfitOptions.at(0)" [max]="netProfitOptions.at(-1)"
                                        (sliderChange)="updateNetProfitRangeSelection($event)"></zint-ngx-slider>
                    
                                    <br>
                                    EBITDA:<br>
                                    
                                    <ng-container *ngFor="let slider of zintSlidersConfigs['ebitda']">
                                        <zint-ngx-slider #ebitdaSliders [sliderConfig]="slider"
                                            (sliderChange)="triggerConfigSliderUpdate($event, slider.label)"></zint-ngx-slider>
                                    </ng-container>
                                    <br>

                                    FX Gain or Loss:<br>
                                    
                                    <ng-container *ngFor="let slider of zintSlidersConfigs['fxLossGain']">
                                        <zint-ngx-slider #fxLossGainSliders [sliderConfig]="slider"
                                            (sliderChange)="triggerConfigSliderUpdate($event, slider.label)"></zint-ngx-slider>
                                    </ng-container>
                                    <br>

                                    Corporation Tax:<br>
                                    
                                    <ng-container *ngFor="let slider of zintSlidersConfigs['corporationTax']">
                                        <zint-ngx-slider #corporationTaxSliders [sliderConfig]="slider"
                                            (sliderChange)="triggerConfigSliderUpdate($event, slider.label)"></zint-ngx-slider>
                                    </ng-container>
                                    <br>
                                    Tax Credit:<br>
                                    
                                    <ng-container *ngFor="let slider of zintSlidersConfigs['taxCredit']">
                                        <zint-ngx-slider #taxCreditSliders [sliderConfig]="slider"
                                            (sliderChange)="triggerConfigSliderUpdate($event, slider.label)"></zint-ngx-slider>
                                    </ng-container>
                                    
                                    <br>
                                    Cash at Bank:<br>
                                    <zint-ngx-slider #cashAtBankSliderElement *ngIf="cashAtBankOptions" prefix="£" maxPostfix="+"
                                        [min]="cashAtBankOptions.at(0)" [max]="cashAtBankOptions.at(-1)"
                                        (sliderChange)="updateCashAtBankRangeSelection($event)"></zint-ngx-slider>
                    
                                    <br>
                                    Current Assets:<br>
                                    <zint-ngx-slider #currentAssetsSliderElement *ngIf="currentAssetsOptions" prefix="£" maxPostfix="+"
                                        [min]="currentAssetsOptions.at(0)" [max]="currentAssetsOptions.at(-1)"
                                        (sliderChange)="updateCurrentAssetsRangeSelection($event)"></zint-ngx-slider>
                    
                                    <br>
                                    Fixed Assets:<br>
                                    <zint-ngx-slider #fixedAssetsSliderElement *ngIf="fixedAssetsOptions" prefix="£" maxPostfix="+"
                                        [min]="fixedAssetsOptions.at(0)" [max]="fixedAssetsOptions.at(-1)"
                                        (sliderChange)="updateFixedAssetsRangeSelection($event)"></zint-ngx-slider>
                    
                                    <br>
                                    Net Assets:<br>
                                    <zint-ngx-slider #netAssetsSliderElement *ngIf="netAssetsOptions" prefix="£" maxPostfix="+"
                                        [min]="netAssetsOptions.at(0)" [max]="netAssetsOptions.at(-1)"
                                        (sliderChange)="updateNetAssetsRangeSelection($event)"></zint-ngx-slider>
                    
                                    <br>
                                    Debtors:<br>
                                    <zint-ngx-slider #debtorsSliderElement *ngIf="debtorsOptions" prefix="£" maxPostfix="+"
                                        [min]="debtorsOptions.at(0)" [max]="debtorsOptions.at(-1)"
                                        (sliderChange)="updateDebtorsRangeSelection($event)"></zint-ngx-slider>
                    
                                    <br>
                                    Creditors:<br>
                                    <zint-ngx-slider #creditorsSliderElement *ngIf="creditorsOptions" prefix="£" maxPostfix="+"
                                        [min]="creditorsOptions.at(0)" [max]="creditorsOptions.at(-1)"
                                        (sliderChange)="updateCreditorsRangeSelection($event)"></zint-ngx-slider>
                    
                                    <br>
                                    <br>
                                    <label>Accounts are overdue
                                        <input type="checkbox" name="accountsAreOverdue" value="false" [(ngModel)]="accountsAreOverdue" />
                                    </label>
                                    <br>
                                    Accounts due in:<br>
                                    <zint-ngx-slider #accountsDueSliderElement *ngIf="accountsDueInRange" postfix=" days"
                                        [min]="accountsDueInRange[0]" [max]="accountsDueInRange[1]"
                                        (sliderChange)="updateAccountsDueInRange($event)"></zint-ngx-slider>
                                    <br>
                                    Latest accounts submitted:<br>
                                    <zint-ngx-slider #latestAccountsSubmittedSliderElement *ngIf="latestAccountsSubmittedRange"
                                        postfix=" days ago" [min]="latestAccountsSubmittedRange[0]" [max]="latestAccountsSubmittedRange[1]"
                                        (sliderChange)="updatelatestAccountsSubmittedInRange($event)"></zint-ngx-slider>
                    
                                    <br>
                                    <br>
                    
                                    <br>Gross Margin:<br>
                                    <zint-ngx-slider #grossMarginSliderElement *ngIf="grossMarginLimits" postfix="%"
                                        [min]="grossMarginLimits[0]" [max]="grossMarginLimits[1]"
                                        (sliderChange)="updateGrossMarginRange($event)"></zint-ngx-slider>
                    
                                    <br>Last year current assets growth:<br>
                                    <zint-ngx-slider #assetsGrowthSliderElement *ngIf="assetsGrowthLimits" postfix="%"
                                        [min]="assetsGrowthLimits[0]" [max]="assetsGrowthLimits[1]"
                                        (sliderChange)="updateAssetsGrowthRange($event)"></zint-ngx-slider>
                    
                                    <br>Last year fixed assets growth:<br>
                                    <zint-ngx-slider #fixedAssetsGrowthSliderElement *ngIf="fixedAssetsGrowthLimits" postfix="%"
                                        [min]="fixedAssetsGrowthLimits[0]" [max]="fixedAssetsGrowthLimits[1]"
                                        (sliderChange)="updateFixedAssetsGrowthRange($event)"></zint-ngx-slider>
                    
                                    <br>Last year revenue growth:<br>
                                    <zint-ngx-slider #revenueGrowthSliderElement *ngIf="revenueGrowthLimits" postfix="%"
                                        [min]="revenueGrowthLimits[0]" [max]="revenueGrowthLimits[1]"
                                        (sliderChange)="updateRevenueGrowthRange($event)"></zint-ngx-slider>
                    
                                    <br>Last year gross profit growth:<br>
                                    <zint-ngx-slider #grossProfitGrowthSliderElement *ngIf="grossProfitGrowthLimits" postfix="%"
                                        maxPostfix="+" [min]="grossProfitGrowthLimits[0]" [max]="grossProfitGrowthLimits[1]"
                                        (sliderChange)="updateGrossProfitGrowthRange($event)"></zint-ngx-slider>
                    
                                    <br><br><br>
                                    <label><input type="checkbox" name="hasRaisedInvestment" value="false"
                                            [(ngModel)]="hasRaisedInvestment" /> Has
                                        raised investment?
                                    </label>
                                    <br>
                                    Amount Raised:<br>
                                    <zint-ngx-slider #investmentRaisedSliderElement *ngIf="investmentRaisedOptions" prefix="£"
                                        [min]="investmentRaisedOptions.at(0)" [max]="investmentRaisedOptions.at(-1)"
                                        (sliderChange)="updateInvestmentRaisedRanges($event)"></zint-ngx-slider>
                    
                                    <span *ngIf="hasShareFilingsAccess">
                                        <br><br><br>
                                        <label><input type="checkbox" name="hasShareFilingEvent" value="false"
                                                [(ngModel)]="hasShareFilingEvent" /> Has
                                            issued or divided shares?
                                        </label>
                                        <br>
                                        Issued or divided shares within:<br>
                                        <zint-ngx-slider #shareFilingEventWithinDaysSliderElement *ngIf="shareFilingEventWithinDaysOptions"
                                            [isRange]="false" postfix=" days" [min]="shareFilingEventWithinDaysOptions[0]"
                                            [max]="shareFilingEventWithinDaysOptions.at(-1)"
                                            (sliderChange)="updateShareFilingEventWithinDays($event)"></zint-ngx-slider>
                                        <br>
                                        With a currency value of:<br>
                                        <zint-ngx-slider #shareFilingCapitalValueSliderElement *ngIf="shareFilingCapitalValueOptions"
                                            maxPostfix="+" [min]="shareFilingCapitalValueOptions.at(0)"
                                            [max]="shareFilingCapitalValueOptions.at(-1)"
                                            (sliderChange)="updateShareFilingCapitalValue($event)">
                                        </zint-ngx-slider>
                                    </span>
                    
                                    <br><br><br>
                                    <label><input type="checkbox" name="issuedAGrant" value="false" [(ngModel)]="issuedAGrant" /> Has been
                                        issued a grant?
                                    </label>
                    
                                    <br>Grant Amount:<br>
                                    <zint-ngx-slider #grantAmountSliderElement *ngIf="grantRangeOptions" prefix="£" maxPostfix="+"
                                        [min]="grantRangeOptions.at(0)" [max]="grantRangeOptions.at(-1)"
                                        (sliderChange)="updateGrantRangeSelected($event)"></zint-ngx-slider>
                    
                                    <br>Grant Project Ends In:<br>
                                    <zint-ngx-slider #grantMonthsUntilProjectEndSliderElement *ngIf="grantMonthsUntilProjectEndRange"
                                        postfix=" months" [min]="grantMonthsUntilProjectEndRange[0]"
                                        [max]="grantMonthsUntilProjectEndRange[1]"
                                        (sliderChange)="updateGrantMonthsUntilProjectEndSelected($event)"></zint-ngx-slider>
                    
                                    <br><br>
                                    <label><input type="checkbox" name="chargeRegistered" value="false" [(ngModel)]="chargeRegistered" />
                                        Has one or more charge registered against it?
                                    </label>
                                    <br>
                                    <label><input type="checkbox" name="outstandingChargeRegistered" value="false"
                                            [(ngModel)]="outstandingChargeRegistered" />
                                        Has one or more outstanding charge registered
                                        against it?
                                    </label>
                    
                                    <br><br>
                                    <label><input type="checkbox" name="hasLEINumber" value="false" [(ngModel)]="hasLEINumber" /> Has an LEI
                                        number?
                                    </label><br>
                    
                                    LEI Registration Status
                                    <select [(ngModel)]="selectedLEIStatus" name="selectedLEIStatus" class="custom-select">
                                        <option *ngFor="let LEIStatusOption of LEIStatusOptions" [ngValue]="LEIStatusOption">
                                            {{LEIStatusOption}}</option>
                                    </select>
                                    <br><br>
                                    Accountant
                                    <input type="text" class="form-control" name="accountantNameContains" [(ngModel)]="accountantNameContains"
                                        (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"><br>
                                    Banker
                                    <input type="text" class="form-control" name="bankerNameContains" [(ngModel)]="bankerNameContains"
                                        (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"><br>
                                </div>
                            </div>
                        </div>
                    
                        <div class="card">
                            <div class="card-header" id="employees-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#employees-search-collapse">
                                        <i class="fal fa-users" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">People</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="employees-search-collapse" class="collapse" data-parent="#search-area">
                                <div class="card-body">
                    
                                    Head Count:
                                    <br>
                                    <label>
                                        <input type="checkbox" name="excludeEstimatedHeadcountFigures" value="false"
                                            [(ngModel)]="excludeEstimatedHeadcountFigures" />
                                        Exclude estimated?
                                    </label>
                    
                                    <zint-ngx-slider #headcountSliderElement *ngIf="employeeRangeFigures" postfix=" employees"
                                        maxPostfix="+" [min]="employeeRangeFigures.at(0)" [max]="employeeRangeFigures.at(-1)"
                                        (sliderChange)="updateEmployeeRange($event)"></zint-ngx-slider>
                    
                                    <br>
                                    Last year head count growth:
                                    <br>
                                    <zint-ngx-slider #headcountGrowthSliderElement *ngIf="headcountGrowthPercentageRange" postfix="%"
                                        [min]="headcountGrowthPercentageRange.at(0)" [max]="headcountGrowthPercentageRange.at(-1)"
                                        (sliderChange)="updateHeadcountGrowthRange($event)"></zint-ngx-slider>
                                    <br>
                    
                                    Departmental Breakdown:
                                    <br>
                    
                                    <zint-ngx-slider #departmentHeadcountSliderElement *ngIf="employeeRangeFigures" postfix=" employees"
                                        maxPostfix="+" [min]="employeeRangeFigures.at(0)" [max]="employeeRangeFigures.at(-1)"
                                        (sliderChange)="updateDepartmentHeadcountRange($event)"></zint-ngx-slider>
                    
                                    <br>
                                    <div class="form-group overflowing-table custom-overflowing-table select-table">
                    
                                        <div class="input-group xsmall-margin-top" style="padding-right: 10px; padding-bottom: 10px">
                                            <div class="input-group-prepend">
                                                <button class="btn basic-button-base button-dark" type="button"
                                                    (click)="resetFunctionalDepartmentSearch()">
                                                    <i class="fal fa-redo-alt"></i>
                                                    Reset
                                                </button>
                                            </div>
                                            <input type="text" class="form-control" name="sicCodeTextSearch"
                                                [(ngModel)]="functionalDepartmentTextSearch" (ngModelChange)="searchFunctionalDepartment()"
                                                placeholder="Search...">
                                        </div>
                    
                                        <label *ngIf="hasSearchedFunctionalDepartment">
                                            <input type="checkbox" name="selectAllVisibleFunctionalDepartments" value="false"
                                                (change)="checkUncheckAllVisibleFunctionalDepartments()"
                                                [(ngModel)]="selectAllVisibleFunctionalDepartments" />
                                            Select all visible
                                        </label>
                    
                                        <div *ngFor="let functionalDepartment of functionalDepartmentOptions">
                                            <div class="cut-off" *ngIf="functionalDepartment.shouldShow">
                                                <label class="expandable-label">
                                                    <input type="checkbox" [attr.name]="functionalDepartment.name"
                                                        [ngModelOptions]="{standalone: true}" value="{{functionalDepartment.name}}"
                                                        [(ngModel)]="functionalDepartment.checked" />
                                                    {{functionalDepartment.name}}
                                                </label>
                                                <hr class="select-break-hr">
                                            </div>
                                        </div>
                    
                                    </div>
                    
                                    <br>
                    
                                    Average Director Age:
                                    <zint-ngx-slider #averageDirectorAgeSliderElement *ngIf="averageDirectorAgeRange" postfix=" years"
                                        [min]="averageDirectorAgeRange[0]" [max]="averageDirectorAgeRange[1]"
                                        (sliderChange)="updateAverageDirectorAgeRange($event)"></zint-ngx-slider>
                    
                                    <br><br>
                    
                                    Number of Directors:
                                    <zint-ngx-slider #numberOfDirectorsSliderElement *ngIf="numberOfDirectorsRange" maxPostfix="+"
                                        [min]="numberOfDirectorsRange[0]" [max]="numberOfDirectorsRange[1]"
                                        (sliderChange)="updateNumberOfDirectorsRange($event)"></zint-ngx-slider>
                    
                                    <br><br>
                    
                                    Number of Persons of Significant Control:
                                    <br>
                                    <label>Include real persons only (and exclude legal
                                        entities)?
                                        <input type="checkbox" name="PSCsRealPersonsOnly" value="false" [(ngModel)]="PSCsRealPersonsOnly" />
                                    </label>
                                    <zint-ngx-slider #numberOfPSCsSliderElement *ngIf="numberOfPSCsRange" maxPostfix="+"
                                        [min]="numberOfPSCsRange[0]" [max]="numberOfPSCsRange[1]"
                                        (sliderChange)="updateNumberOfPSCsRange($event)"></zint-ngx-slider>
                    
                                    <br><br>
                    
                                    <span>New officer with job title </span>
                                    <input type="text" class="form-control" name="recentDirectorshipTitleContains"
                                        [(ngModel)]="recentDirectorshipTitleContains"
                                        (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)" placeholder="Leave blank for any title">
                                    <span>appointed in the last:</span>
                                    <zint-ngx-slider #recentDirectorshipMonthRangeSliderElement *ngIf="recentDirectorshipMonthRange"
                                        postfix=" months" [min]="recentDirectorshipMonthRange[0]" [max]="recentDirectorshipMonthRange[1]"
                                        (sliderChange)="updateRecentDirectorshipMonthRange($event)"></zint-ngx-slider>
                    
                                    <br><br>
                    
                                    <div class="row">
                                        <div class="col-md-6 d-sm-none d-xs-none d-md-block">
                                            Nationalities of Officers:
                                        </div>
                                        <div class="col-md-6 d-sm-none d-xs-none d-md-block">
                                            Nationalities of Persons of Significant
                                            Control:
                                        </div>
                                    </div>


                                    <div class="row">
                                        <div class="col-md-6">
                                            <span class="d-md-none">Nationalities of
                                                Officers:</span>
                                            <div class="form-group overflowing-table select-table">
                                                <div *ngFor="let nationalitiesOfDirectorsOption of nationalitiesOfDirectorsOptions">
                                                    <label>
                                                        <input type="checkbox"
                                                            [attr.name]="'nationalitiesOfDirectorsOption' + nationalitiesOfDirectorsOption.code"
                                                            [ngModelOptions]="{standalone: true}"
                                                            value="{{nationalitiesOfDirectorsOption.code}}"
                                                            [(ngModel)]="nationalitiesOfDirectorsOption.checked" />
                                                        {{
                                                        nationalitiesOfDirectorsOption.name
                                                        }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <span class="d-md-none">Nationalities of
                                                Persons of Significant Control:</span>
                                            <div class="form-group overflowing-table select-table">
                                                <div *ngFor="let nationalitiesOfPSCsOption of nationalitiesOfPSCsOptions">
                                                    <label>
                                                        <input type="checkbox"
                                                            [attr.name]="'nationalitiesOfPSCsOption' + nationalitiesOfPSCsOption.code"
                                                            [ngModelOptions]="{standalone: true}" value="{{nationalitiesOfPSCsOption.code}}"
                                                            [(ngModel)]="nationalitiesOfPSCsOption.checked" />
                                                        {{
                                                        nationalitiesOfPSCsOption.name
                                                        }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                    
                                    <br>
                    
                                    Member of Leadership Team has Job Title Containing:
                                    <input type="text" class="form-control" name="directorJobTitleContains"
                                        [(ngModel)]="directorJobTitleContains"
                                        (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">

                                    <br>
                                    Wages:<br>
                                    
                                    <ng-container *ngFor="let slider of zintSlidersConfigs['wages']">
                                        <zint-ngx-slider #wagesSliders [sliderConfig]="slider"
                                            (sliderChange)="triggerConfigSliderUpdate($event, slider.label)"></zint-ngx-slider>
                                    </ng-container>
                                    <br>
                                </div>
                            </div>
                        </div>
                    
                        <div class="card">
                            <div class="card-header" id="importsexports-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#importsexports-search-collapse">
                                        <i class="fal fa-ship" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Imports
                                            and Exports</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="importsexports-search-collapse" class="collapse" data-parent="#search-area">
                                <div class="card-body">
                                    Import Export Products:
                                    <div class="form-group overflowing-table select-table" id="import-export-overflowing-table">
                    
                                        <div class="input-group xsmall-margin-top" style="padding-right: 10px; padding-bottom: 10px">
                                            <div class="input-group-prepend">
                                                <button class="btn basic-button-base button-dark" type="button"
                                                    (click)="resetImportExportSearch()">
                                                    <i class="fal fa-redo-alt" *ngIf="!isLoading.loadingImportExportCodes"></i>
                                                    <i class="fal fa-spinner fa-spin" *ngIf="isLoading.loadingImportExportCodes"></i>
                                                    Reset
                                                </button>
                                            </div>
                                            <input type="text" class="form-control" name="importExportTextSearch"
                                                [(ngModel)]="importExportTextSearch" (keyup.enter)="searchImportExportCodes()"
                                                placeholder="Search...">
                                        </div>
                    
                                        <div *ngFor="let importExportCategory of importExportCategories">
                                            <div *ngIf="importExportCategory.shouldShow" class="cut-off">
                                                <label class="expandable-label">
                                                    <input type="checkbox"
                                                        [attr.name]="'importExportCategory' + importExportCategory.unique_identifier"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (change)="updateFilters(); updateToDefaultImportingOrExporting()"
                                                        value="{{importExportCategory.unique_identifier}}"
                                                        [(ngModel)]="importExportCategory.checked" />
                                                </label>
                                                <span (click)="expandOrCollapseImportExportCategories(importExportCategory)"
                                                    class="expandable-span"><ng-container
                                                        *ngFor="let dummy of ' '.repeat(importExportCategory.level).split(' ')">&nbsp;&nbsp;</ng-container><i
                                                        class="fal fa-caret-down" *ngIf="importExportCategory.level > 0"></i>
                                                    {{importExportCategory.description}}</span>
                                                <hr class="select-break-hr">
                                            </div>
                                        </div>
                                    </div>
                    
                                    <div class="row xsmall-margin-bottom" style="padding-left: 1.25rem;">
                                        <div style="display:inline; margin-top: 5px; margin-right: 2px">
                                            Has&nbsp;
                                        </div>
                                        <div style="display:inline">
                                            <select [(ngModel)]="selectedImportingOrExportingOption"
                                                name="selectedImportingOrExportingOption" class="custom-select">
                                                <option *ngFor="let importExportOption of importingExportingBothOptions"
                                                    [ngValue]="importExportOption.identifier">
                                                    {{importExportOption.description_past}}</option>
                                            </select>
                                        </div>
                                        <div style="display:inline; margin-top: 5px; margin-left: 2px">
                                            &nbsp;<span *ngIf="selectedImportExportCategories?.length > 0">any
                                                of the above
                                                selected products</span> <span
                                                *ngIf="selectedImportExportCategories?.length == 0">anything</span>
                                            in the last:
                                        </div>
                                    </div>
                                    <zint-ngx-slider #importExportSliderElement *ngIf="importExportMonthRange" [isRange]="false"
                                        postfix=" months" maxPostfix="+" [min]="importExportMonthRange[0]" [max]="importExportMonthRange[1]"
                                        (sliderChange)="updateImportExportTimeScale($event)"></zint-ngx-slider>
                    
                                    <br><br>
                    
                                    <hr>
                    
                                    <div class="row xsmall-margin-bottom" style="padding-left: 1.25rem;">
                                        <div style="display:inline; margin-top: 5px; margin-right: 2px">
                                            Has&nbsp;
                                        </div>
                                        <div style="display:inline">
                                            <select [(ngModel)]="importExportFrequencyImportingOrExporting"
                                                name="importExportFrequencyImportingOrExporting" class="custom-select">
                                                <option *ngFor="let importExportOption of importingExportingBothOptions"
                                                    [ngValue]="importExportOption.identifier">
                                                    {{importExportOption.description_past}}</option>
                                            </select>
                                        </div>
                                        <div style="display:inline; margin-top: 5px; margin-left: 2px">
                                            &nbsp;anything at least
                                        </div>
                                    </div>
                    
                                    <zint-ngx-slider #importExportFrequencySliderElement *ngIf="importExportFrequencyRange"
                                        [isRange]="false" postfix=" times" maxPostfix="+" [min]="importExportFrequencyRange[0]"
                                        [max]="importExportFrequencyRange[1]"
                                        (sliderChange)="updateImportExportFrequency($event)"></zint-ngx-slider>
                    
                                    <br>
                                    in the last
                                    <br><br>
                    
                                    <zint-ngx-slider #importExportFrequencyMonthCountSliderElement
                                        *ngIf="importExportFrequencyMonthCountRange" [isRange]="false" postfix=" months" maxPostfix="+"
                                        [min]="importExportFrequencyMonthCountRange[0]" [max]="importExportFrequencyMonthCountRange[1]"
                                        (sliderChange)="updateImportExportFrequencyMonthCount($event)"></zint-ngx-slider>
                    
                                </div>
                            </div>
                        </div>
                    
                        <div class="card">
                            <div class="card-header" id="assets-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" data-toggle="collapse" data-target="#assetContainer">
                                        <i class="fal fa-car-building" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Assets</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="assetContainer" class="collapse" data-parent="#search-area">
                                <div class="card-body p-1" id="assetWrapperCard">
                    
                                    <div class="card mb-1">
                                        <div class="card-header" role="button" data-toggle="collapse" data-target="#tangibleCollapse">
                                            <button class="btn btn-link">
                                                <i class="fal fa-warehouse fa-sm mr-1"></i><span *ngIf="!this.isSidebarCollapsed">Tangible
                                                    Assets</span>
                                            </button>
                                        </div>
                                        <div class="card-body collapse" data-parent="#assetWrapperCard" id="tangibleCollapse">
                                            <ng-container *ngFor="let slider of zintSlidersConfigs['tangibleAssets']">
                    
                                                <zint-ngx-slider #tanAssetsSliders [sliderConfig]="slider"
                                                    (sliderChange)="triggerConfigSliderUpdate($event, slider.label)"></zint-ngx-slider>
                                            </ng-container>
                                        </div>
                                    </div>
                    
                                    <div class="card">
                                        <div class="card-header" role="button" data-toggle="collapse" data-target="#intangibleCollapse">
                                            <button class="btn btn-link">
                                                <i class="fal fa-laptop-code fa-sm mr-1"></i><span
                                                    *ngIf="!this.isSidebarCollapsed">Intangible
                                                    Assets</span>
                                            </button>
                    
                                        </div>
                                        <div class="card-body collapse" data-parent="#assetWrapperCard" id="intangibleCollapse">
                                            <ng-container *ngFor="let slider of zintSlidersConfigs['intangibleAssets']">
                                                <zint-ngx-slider #intanAssetsSliders [sliderConfig]="slider"
                                                    (sliderChange)="triggerConfigSliderUpdate($event, slider.label)"></zint-ngx-slider>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                        </div>
                    
                        <div class="card">
                            <div class="card-header" id="website-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#website-search-collapse">
                                        <i class="fal fa-browser" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Website
                                            Analysis</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="website-search-collapse" class="collapse" data-parent="#search-area">
                                <div class="card-body">
                    
                                    <label>
                                        <input type="checkbox" name="hasWebsite" value="false" [(ngModel)]="hasWebsite" />
                                        Has website
                                    </label>
                                    <br>
                    
                                    <label>
                                        <input type="checkbox" name="hasPhoneNumber" value="false" [(ngModel)]="hasPhoneNumber" />
                                        Has a phone number
                                    </label>
                                    <br>
                    
                                    <label>
                                        <input type="checkbox" name="hasEmailAddress" value="false" [(ngModel)]="hasEmailAddress" />
                                        Has an email address
                                    </label>
                                    <br><br>
                    
                                    Web Technologies:
                                    <div class="form-group overflowing-table select-table">
                                        <div class="input-group xsmall-margin-top" style="padding-right: 10px; padding-bottom: 10px">
                                            <div class="input-group-prepend">
                                                <button class="btn basic-button-base button-dark" type="button" (click)="resetWebTechs()">
                                                    <i class="fal fa-redo-alt"></i>
                                                    Reset
                                                </button>
                                            </div>
                                            <input type="text" class="form-control" name="sicCodeTextSearch"
                                                [(ngModel)]="webTechsTextSearch" (ngModelChange)="searchWebTechs()" placeholder="Search...">
                                        </div>
                                        <div *ngFor="let webTechnologyOption of webTechnologyOptions">
                                            <div *ngIf="webTechnologyOption.shouldShow">
                                                <label>
                                                    <input type="checkbox" [attr.name]="'webTechnologyOption' + webTechnologyOption.name"
                                                        [ngModelOptions]="{standalone: true}" value="{{webTechnologyOption.name}}"
                                                        [(ngModel)]="webTechnologyOption.checked" />
                                                </label>
                                                <span (click)="expandOrCollapseItems(webTechnologyOption.children, 'webTechnologyOptions')"
                                                    [ngClass]="{'expandable-span': webTechnologyOption.level==0}">
                                                    <span *ngIf="webTechnologyOption.level==1">&nbsp;&nbsp;
                                                        <i class="fal fa-caret-down"></i>
                                                    </span>
                                                    {{webTechnologyOption.description}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                    
                                    <br>
                                    Estimated Average Monthly Website Visitors:
                                    <br>
                                    <zint-ngx-slider #websiteVisitorsSliderElement *ngIf="employeeRangeFigures" maxPostfix="+"
                                        [min]="webTrafficOptions.at(0)" [max]="webTrafficOptions.at(-1)"
                                        (sliderChange)="updateWebTraffic($event)"></zint-ngx-slider>
                    
                                    <br><br>
                    
                                    Homepage Website Text Search:
                                    <p style="font-size: 10px">Use NOT for negative
                                        search, AND for multiple phrases, OR for
                                        different options, and inverted commas (") for
                                        exact phrases. Each term taken as OR by
                                        default.</p>
                                    <input type="text" class="form-control" name="homepageSearchTerm" [(ngModel)]="homepageSearchTerm"
                                        (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"><br>
                    
                                    <div [ngClass]="{'hide-deep-crawl': !hasDeepCrawlAccess}">
                                        Zint Deep Crawl <sup><i class="fal fa-bolt" matTooltipPosition="above"
                                                matTooltip="This is a Zint Power Tool"></i></sup>
                                        Website Text Search:
                                        <p style="font-size: 10px" *ngIf="hasDeepCrawlAccess">Use NOT for
                                            negative search, AND
                                            for multiple phrases, OR for different
                                            options, and inverted commas (") for exact
                                            phrases. Each term taken as OR by
                                            default.</p>
                                        <p style="font-size: 10px" *ngIf="!hasDeepCrawlAccess"><i class="fal fa-ban"></i> Zint
                                            Deep Crawl allows you to search company
                                            websites beyond just the homepage. You do
                                            not have access to this on your plan. Please
                                            <a href="mailto:sales@zint.io?Subject=Zint%20Deep%20Crawl%20Enquiry">contact
                                                us</a>
                                            to upgrade.
                                        </p>
                                        <input type="text" class="form-control" [attr.disabled]="!hasDeepCrawlAccess ? 'disabled': null"
                                            name="fullWebsiteSearchTerm" [(ngModel)]="fullWebsiteSearchTerm"
                                            (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"><br>
                                    </div>
                    
                                    <div class="website-keyword-section">
                                        <div class="dropdown">
                                            Website Keywords
                                            <div *ngIf="websiteKeywords && websiteKeywords.length > 0">
                                                <span *ngFor="let websiteKeyword of websiteKeywords" class="badge"
                                                    style="background-color: #000a36; color: white; margin-right: 7px; margin-bottom: 8px">
                                                    {{ websiteKeyword }} <i class="fal fa-times tag-delete-button cursor-pointer"
                                                        (click)="removeWebsiteKeyword(websiteKeyword)"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control postcode-radius-text-box dropdown-toggle"
                                                name="websiteKeywordInput" placeholder="e.g. cryptocurrency" data-toggle="dropdown"
                                                [(ngModel)]="websiteKeywordInput" (keyup.enter)="forceAddWebsiteKeyword($event)"
                                                (ngModelChange)="websiteKeywordChanged()">
                                            <div class="dropdown-menu">
                                                <div class="d-flex justify-content-center align-items-center">
                                                    <zint-loader size="xSmall" *ngIf="isLoading.loadingWebsiteKeywords"></zint-loader>
                                                </div>
                                                <span *ngFor="let possibleWebsiteKeyword of possibleWebsiteKeywords"
                                                    class="dropdown-item cursor-pointer"
                                                    (click)="addWebsiteKeyword(possibleWebsiteKeyword)">
                                                    <span>{{ possibleWebsiteKeyword
                                                        }}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                    
                                </div>
                            </div>
                        </div>
                    
                        <div class="card">
                            <div class="card-header" id="trademarks-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#trademarks-search-collapse">
                                        <i class="fal fa-registered" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Trademarks</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="trademarks-search-collapse" class="collapse custom-overflowing-table-collapse"
                                data-parent="#search-area">
                                <div class="card-body">
                    
                                    Trademark Name Contains:
                                    <input type="text" class="form-control" name="trademarkNameContains" [(ngModel)]="trademarkNameContains"
                                        style="width: 100%" placeholder="e.g. Marmite"
                                        (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"><br>
                    
                                    Trademark Description Contains:
                                    <input type="text" class="form-control" name="trademarkDescriptionContains"
                                        [(ngModel)]="trademarkDescriptionContains" style="width: 100%"
                                        placeholder="e.g. artificial intelligence"
                                        (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"><br>
                    
                                    <br>
                                    Trademark Status:
                                    <div class="form-group select-table">
                                        <div *ngFor="let trademarkStatusOption of trademarkStatusOptions">
                                            <div *ngIf="trademarkStatusOption.level == 0 || trademarkStatusOption.shouldShow">
                                                <label>
                                                    <input type="checkbox"
                                                        [attr.name]="'trademarkStatusOption' + trademarkStatusOption.name"
                                                        [ngModelOptions]="{standalone: true}" value="{{trademarkStatusOption.name}}"
                                                        [(ngModel)]="trademarkStatusOption.checked" />
                                                </label>
                                                <span
                                                    (click)="expandOrCollapseItems(trademarkStatusOption.children, 'trademarkStatusOptions')"
                                                    [ngClass]="{'expandable-span': trademarkStatusOption.level==0}"><span
                                                        *ngIf="trademarkStatusOption.level==1">&nbsp;&nbsp;
                                                        <i class="fal fa-caret-down"></i>
                                                    </span>
                                                    {{trademarkStatusOption.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                    
                                    <br>
                                    Trademark Class:
                                    <div class="form-group custom-overflowing-table select-table overflowing-table">
                                        <div *ngFor="let trademarkClassOption of trademarkClassOptions">
                                            <div class="cut-off">
                                                <label>
                                                    <input type="checkbox" [attr.name]="'trademarkClassOption' + trademarkClassOption.id"
                                                        [ngModelOptions]="{standalone: true}" value="{{trademarkClassOption.id}}"
                                                        [(ngModel)]="trademarkClassOption.checked" />
                                                </label>
                                                <span>&nbsp;&nbsp;
                                                    {{trademarkClassOption.number}}:
                                                    {{trademarkClassOption.description
                                                    }}</span>
                                            </div>
                                        </div>
                                    </div>
                    
                                </div>
                            </div>
                        </div>
                    
                        <div class="card">
                            <div class="card-header" id="hiring-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#hiring-search-collapse">
                                        <i class="fal fa-user-tie" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Hiring</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="hiring-search-collapse" class="collapse" data-parent="#search-area">
                                <div class="card-body">
                                    <label>
                                        <input type="checkbox" name="isHiring" value="false" [(ngModel)]="isHiring" /> Is
                                        hiring?
                                    </label>
                                    <br>
                                    <label>
                                        <input type="checkbox" name="includeRecruiters" value="false" [(ngModel)]="includeRecruiters" />
                                        Include
                                        recruiter listings?
                                    </label>
                    
                                    <br>
                                    <br>
                                    For position(s) containing job title:
                                    <input type="text" class="form-control" name="hiringJobTitleContains"
                                        [(ngModel)]="hiringJobTitleContains" (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"><br>
                    
                                    Job description contains the following text:<br>
                                    <span style="font-size: 10px">Use NOT for negative
                                        search, AND for multiple phrases, OR for
                                        different options, and inverted commas (") for
                                        exact phrases. Each term taken as OR by
                                        default.</span>
                                    <input type="text" class="form-control" name="hiringDescriptionContains"
                                        [(ngModel)]="hiringDescriptionContains"
                                        (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"><br>
                    
                                    In the last
                                    <zint-ngx-slider #hiringDayLimitSliderElement *ngIf="hiringDayLimits" [isRange]="false" postfix=" days"
                                        maxPostfix="+" [min]="hiringDayLimits[0]" [max]="hiringDayLimits[1]"
                                        (sliderChange)="updateHiringDayRange($event)"></zint-ngx-slider>
                                    <br>
                    
                                    <br>
                                    <br>
                                    Number of roles hiring for:
                                    <zint-ngx-slider #numberOfRolesHiringForSliderElement *ngIf="numberOfRolesHiringForLimits"
                                        postfix=" roles" [min]="numberOfRolesHiringForLimits[0]" [max]="numberOfRolesHiringForLimits[1]"
                                        (sliderChange)="updateNumberOfRolesHiringForRange($event)"></zint-ngx-slider>
                                    <br>In the last
                                    <zint-ngx-slider #numberOfRolesHiringForDayLimitSliderElement *ngIf="numberOfRolesHiringForDayLimits"
                                        [isRange]="false" postfix=" days" maxPostfix="+" [min]="numberOfRolesHiringForDayLimits[0]"
                                        [max]="numberOfRolesHiringForDayLimits[1]"
                                        (sliderChange)="updateNumberOfRolesHiringForDayRange($event)"></zint-ngx-slider>
                    
                                </div>
                            </div>
                        </div>
                    
                        <div class="card">
                            <div class="card-header" id="timing-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#timing-search-collapse">
                                        <i class="fal fa-clock" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Timing</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="timing-search-collapse" class="collapse" data-parent="#search-area">
                                <div class="card-body">
                    
                                    Days until financial year end:<br>
                                    <zint-ngx-slider #daysUntilFinancialYearEndSliderElement *ngIf="daysUntilFinancialYearEndLimits"
                                        postfix=" days" [min]="daysUntilFinancialYearEndLimits[0]"
                                        [max]="daysUntilFinancialYearEndLimits[1]"
                                        (sliderChange)="updateFinancialYearEndRange($event)"></zint-ngx-slider>
                    
                                    <br>
                    
                                    <div class="form-group select-table">
                                        <label for="options">Financial year
                                            ends:</label>
                                        <div *ngFor="let financialYearEndsOption of financialYearEndsOptions">
                                            <label>
                                                <input type="checkbox"
                                                    [attr.name]="'financialYearEndsOption' + financialYearEndsOption.value"
                                                    [ngModelOptions]="{standalone: true}" value="{{financialYearEndsOption.value}}"
                                                    [(ngModel)]="financialYearEndsOption.checked" />
                                                {{ financialYearEndsOption.name }}
                                            </label>
                                        </div>
                                    </div>
                    
                                    <br>
                    
                                    Days until incorporation anniversary:<br>
                                    <zint-ngx-slider #daysUntilAnniversarySliderElement *ngIf="daysUntilAnniversaryLimits" postfix=" days"
                                        [min]="daysUntilAnniversaryLimits[0]" [max]="daysUntilAnniversaryLimits[1]"
                                        (sliderChange)="updateAnniversaryRange($event)"></zint-ngx-slider>
                    
                                </div>
                            </div>
                        </div>
                    
                        <div class="card">
                            <div class="card-header" id="events-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#events-search-collapse">
                                        <i class="fal fa-calendar-alt" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Events</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="events-search-collapse" class="collapse" data-parent="#search-area">
                                <div class="card-body">
                                    <div>
                                        <div class="dropdown">
                                            <span
                                                [ngClass]="{'very-transparent-text': postcodeEventsWithinRadius && postcodeEventsWithinRadius.length > 0}">Search
                                                for events by name:
                                                <sup>(beta)</sup></span>
                                            <div *ngIf="selectedEvents && selectedEvents.length > 0"
                                                [ngClass]="{'very-transparent-text': postcodeEventsWithinRadius && postcodeEventsWithinRadius.length > 0}">
                                                <span *ngFor="let selectedEvent of selectedEvents" class="badge"
                                                    style="background-color: #000a36; color: white; margin-right: 7px; margin-bottom: 8px">
                                                    {{ selectedEvent.name }} <i class="fal fa-times tag-delete-button cursor-pointer"
                                                        (click)="removeMatchingEvent(selectedEvent)"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control postcode-radius-text-box dropdown-toggle"
                                                name="eventNameSearch" placeholder="e.g. sales expo" data-toggle="dropdown"
                                                [(ngModel)]="eventNameSearchInput" (ngModelChange)="eventNameSearchChanged()"
                                                [ngClass]="{'very-transparent-text': postcodeEventsWithinRadius && postcodeEventsWithinRadius.length > 0}">
                                            <div class="dropdown-menu">
                                                <div class="d-flex justify-content-center align-items-center">
                                                    <zint-loader size="xSmall" *ngIf="isLoading.loadingMatchingEventNames"></zint-loader>
                                                </div>
                                                <span *ngFor="let possibleMatchingEvent of possibleMatchingEvents"
                                                    class="dropdown-item cursor-pointer" (click)="addMatchingEvent(possibleMatchingEvent)">
                                                    <span>{{ possibleMatchingEvent.name
                                                        }}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div [ngClass]="{'very-transparent-text': selectedEvents && selectedEvents.length > 0}">
                                        Exhibiting at an event within radius of
                                        postcode:
                                        <input type="text" class="form-control" name="postcodeEventsWithinRadius"
                                            (ngModelChange)="this.selectedEvents = []" [(ngModel)]="postcodeEventsWithinRadius"
                                            style="width: 100px; display:inline" (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                                        <br><br>
                                        <zint-ngx-slider #eventsMileRadiusSliderElement *ngIf="mileRadiusEventsRange" [isRange]="false"
                                            postfix=" miles" [min]="mileRadiusEventsRange[0]" [max]="mileRadiusEventsRange[1]"
                                            (sliderChange)="updateEventsMileageRange($event)"></zint-ngx-slider>
                                    </div>
                    
                                    <br>
                                    ...in the next...
                                    <br><br>
                                    <zint-ngx-slider #eventsDayRangeSliderElement *ngIf="eventDaysRange" postfix=" days" maxPostfix="+"
                                        [min]="eventDaysRange[0]" [max]="eventDaysRange[1]"
                                        (sliderChange)="updateEventsDaysRange($event)"></zint-ngx-slider>
                    
                                </div>
                            </div>
                        </div>
                    
                        <div class="card">
                            <div class="card-header" id="news-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#news-search-collapse">
                                        <i class="fal fa-newspaper" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">News</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="news-search-collapse" class="collapse" data-parent="#search-area">
                                <div class="card-body">
                                    <div>
                                        Mentioned in news article containing the
                                        following text:<br>
                                        <span style="font-size: 10px">Use NOT for
                                            negative search, AND for multiple phrases,
                                            OR
                                            for different options, and inverted commas
                                            (") for exact phrases. Each term taken as
                                            OR by default.</span>
                                        <input type="text" class="form-control" name="newsContaining" [(ngModel)]="newsContaining"
                                            (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                                    </div>
                                    News Source:
                                    <select [(ngModel)]="newsSource" name="newsSource" class="custom-select">
                                        <option value="external">
                                            External Publication
                                        </option>
                                        <option value="selfPublished">
                                            Self Published
                                        </option>
                                        <option value="either">
                                            Either
                                        </option>
                                    </select>
                                    Within:<br>
                                    <zint-ngx-slider #newsDayRangeSliderElement *ngIf="newsDaysRange" [isRange]="false" postfix=" days"
                                        maxPostfix="+" [min]="newsDaysRange[0]" [max]="newsDaysRange[1]"
                                        (sliderChange)="updateNewsDays($event)"></zint-ngx-slider>
                                </div>
                            </div>
                        </div>
                    
                        <div class="card">
                            <div class="card-header" id="other-attrs-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#other-attrs-search-collapse">
                                        <i class="fal fa-shopping-cart" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Marketplaces</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="other-attrs-search-collapse" class="collapse" data-parent="#search-area">
                                <div class="card-body">
                                    <label>
                                        <input type="checkbox" name="amazonSeller" value="false" [(ngModel)]="amazonSeller" />
                                        Is an Amazon seller
                                    </label><br>
                                    <label>
                                        <input type="checkbox" name="listedOnGCloud" value="false" [(ngModel)]="listedOnGCloud" />
                                        Is listed on the Government "G Cloud" Digital
                                        Marketplace as a seller of digital
                                        products/services available to public sector
                                        organisations
                                    </label><br>
                                </div>
                            </div>
                        </div>
                    
                        <div class="card" [ngClass]="{'card-disabled': !hasEnergyAndEmissionsAccess}">
                            <div class="card-header" id="energy-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#energy-collapse">
                                        <i class="fal fa-plug" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Energy</span>
                                        <i class="fal float-right" [ngClass]="hasEnergyAndEmissionsAccess? 'fa-bolt': 'fa-ban'"
                                            matTooltipPosition="above" style="margin-top: 4px;"
                                            [matTooltip]="hasEnergyAndEmissionsAccess ? 'This is a Zint Power Tool': 'You do not have access to the Energy and Emissions data'"></i>
                                    </button>
                                </h5>
                            </div>
                            <div id="energy-collapse" class="collapse" data-parent="#search-area">
                                <ng-container *ngIf="hasEnergyAndEmissionsAccess">
                                    <div class="card-body">
                                        <br>
                                        <label>
                                            <input type="checkbox" name="isLowEnergy" value="false" [(ngModel)]="isLowEnergy" />
                                            Reported Low Energy Usage
                                        </label>
                                        <i class="fal float-right fa-question-circle" matTooltipPosition="above" style="margin-top: 4px;"
                                            [matTooltip]="'Large companies that have consumed (in the UK), less than 40,000 kilowatt-hours (kWh) of energy'"></i>
                    
                                        <br>
                                        <br>
                                        Total Energy:<br>
                                        <zint-ngx-slider #totalEnergyKwhSliderElement *ngIf="totalEnergyKwhOptions" postfix=" kWh"
                                            maxPostfix="+" [min]="totalEnergyKwhOptions.at(0)" [max]="totalEnergyKwhOptions.at(-1)"
                                            (sliderChange)="updateTotalEnergyKwhRangeSelection($event)"></zint-ngx-slider>
                                        <br>
                                        Gas Energy Usage:<br>
                                        <zint-ngx-slider #gasKwhSliderElement *ngIf="gasKwhOptions" postfix=" kWh" maxPostfix="+"
                                            [min]="gasKwhOptions.at(0)" [max]="gasKwhOptions.at(-1)"
                                            (sliderChange)="updateGasKwhRangeSelection($event)"></zint-ngx-slider>
                                        <br>
                                        Electric Energy Usage:<br>
                                        <zint-ngx-slider #electricKwhSliderElement *ngIf="electricKwhOptions" postfix=" kWh" maxPostfix="+"
                                            [min]="electricKwhOptions.at(0)" [max]="electricKwhOptions.at(-1)"
                                            (sliderChange)="updateElectricKwhRangeSelection($event)"></zint-ngx-slider>
                                        <br>
                                        Scope 1 Energy Usage:<br>
                                        <zint-ngx-slider #scope1KwhSliderElement *ngIf="scope1KwhOptions" postfix=" kWh" maxPostfix="+"
                                            [min]="scope1KwhOptions.at(0)" [max]="scope1KwhOptions.at(-1)"
                                            (sliderChange)="updateScope1KwhRangeSelection($event)"></zint-ngx-slider>
                                        <br>
                                        Scope 2 Energy Usage:<br>
                                        <zint-ngx-slider #scope2KwhSliderElement *ngIf="scope2KwhOptions" postfix=" kWh" maxPostfix="+"
                                            [min]="scope2KwhOptions.at(0)" [max]="scope2KwhOptions.at(-1)"
                                            (sliderChange)="updateScope2KwhRangeSelection($event)"></zint-ngx-slider>
                                        <br>
                                        Scope 3 Energy Usage:<br>
                                        <zint-ngx-slider #scope3KwhSliderElement *ngIf="scope3KwhOptions" postfix=" kWh" maxPostfix="+"
                                            [min]="scope3KwhOptions.at(0)" [max]="scope3KwhOptions.at(-1)"
                                            (sliderChange)="updateScope3KwhRangeSelection($event)"></zint-ngx-slider>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    
                        <div class="card" [ngClass]="{'card-disabled': !hasEnergyAndEmissionsAccess}">
                            <div class="card-header" id="emissions-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#emissions-collapse">
                                        <i class="fal fa-campfire" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Emissions</span>
                                        <i class="fal float-right" [ngClass]="hasEnergyAndEmissionsAccess? 'fa-bolt': 'fa-ban'"
                                            matTooltipPosition="above" style="margin-top: 4px;"
                                            [matTooltip]="hasEnergyAndEmissionsAccess ? 'This is a Zint Power Tool': 'You do not have access to the Energy and Emissions data'"></i>
                                    </button>
                                </h5>
                            </div>
                            <div id="emissions-collapse" class="collapse" data-parent="#search-area">
                                <ng-container *ngIf="hasEnergyAndEmissionsAccess">
                                    <div class="card-body">
                                        <br>
                                        Total Emissions:<br>
                                        <zint-ngx-slider #totalEmissionsKgCo2eSliderElement *ngIf="totalEmissionsKgCo2eOptions"
                                            postfix=" KgCO2e" maxPostfix="+" [min]="totalEmissionsKgCo2eOptions.at(0)"
                                            [max]="totalEmissionsKgCo2eOptions.at(-1)"
                                            (sliderChange)="updateTotalEmissionsKgCo2eRangeSelection($event)"></zint-ngx-slider>
                                        <br>
                                        Scope 1 Emissions:<br>
                                        <zint-ngx-slider #scope1KgCo2eSliderElement *ngIf="scope1KgCo2eOptions" postfix=" KgCO2e"
                                            maxPostfix="+" [min]="scope1KgCo2eOptions.at(0)" [max]="scope1KgCo2eOptions.at(-1)"
                                            (sliderChange)="updateScope1KgCo2eRangeSelection($event)"></zint-ngx-slider>
                                        <br>
                                        Scope 2 Emissions:<br>
                                        <zint-ngx-slider #scope2KgCo2eSliderElement *ngIf="scope2KgCo2eOptions" postfix=" KgCO2e"
                                            maxPostfix="+" [min]="scope2KgCo2eOptions.at(0)" [max]="scope2KgCo2eOptions.at(-1)"
                                            (sliderChange)="updateScope2KgCo2eRangeSelection($event)"></zint-ngx-slider>
                                        <br>
                                        Scope 3 Emissions:<br>
                                        <zint-ngx-slider #scope3KgCo2eSliderElement *ngIf="scope3KgCo2eOptions" postfix=" KgCO2e"
                                            maxPostfix="+" [min]="scope3KgCo2eOptions.at(0)" [max]="scope3KgCo2eOptions.at(-1)"
                                            (sliderChange)="updateScope3KgCo2eRangeSelection($event)"></zint-ngx-slider>
                                        <br>
                                        KgCO2e Per Million Revenue:<br>
                                        <zint-ngx-slider #kgCo2ePerMillionRevenueSliderElement *ngIf="kgCo2ePerMillionRevenueOptions"
                                            postfix=" KgCO2e" maxPostfix="+" [min]="kgCo2ePerMillionRevenueOptions.at(0)"
                                            [max]="kgCo2ePerMillionRevenueOptions.at(-1)"
                                            (sliderChange)="updateKgCo2ePerMillionRevenueRangeSelection($event)"></zint-ngx-slider>
                                        <br>
                                        KgCO2e Per Employee:<br>
                                        <zint-ngx-slider #kgCo2ePerEmployeeSliderElement *ngIf="kgCo2ePerEmployeeOptions" postfix=" KgCO2e"
                                            maxPostfix="+" [min]="kgCo2ePerEmployeeOptions.at(0)" [max]="kgCo2ePerEmployeeOptions.at(-1)"
                                            (sliderChange)="updateKgCo2ePerEmployeeRangeSelection($event)"></zint-ngx-slider>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    
                        <ng-container *ngIf="hasSupplierDataAccessMessage">
                            <div class="card" [ngClass]="{'card-disabled': !hasSupplierDataAccessMessage}">
                                <div class="card-header" id="suppliers-heading">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#suppliers-collapse">
                                            <i class="fal fa-store" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                            <span *ngIf="!this.isSidebarCollapsed">Supplier
                                                Relationships</span>
                                            <i class="fal float-right" [ngClass]="hasSupplierDataAccessMessage? 'fa-bolt': 'fa-ban'"
                                                matTooltipPosition="above" style="margin-top: 4px;"
                                                [matTooltip]="hasSupplierDataAccessMessage ? 'This is a Zint Power Tool': 'You do not have access to Supplier Data'"></i>
                                        </button>
                                    </h5>
                                </div>
                                <div id="suppliers-collapse" class="collapse" data-parent="#search-area">
                                    <div class="card-body">
                                        <label>
                                            Is a likely supplier of companies tagged
                                            with:
                                            <select *ngIf="tagsPositiveOptions && tagsPositiveOptions.length === 0" class="custom-select">
                                                <option>No Tags Available</option>
                                            </select>
                                            <select *ngIf="tagsPositiveOptions && tagsPositiveOptions.length > 0"
                                                [(ngModel)]="supplierTagSelected" name="supplierTagSelected" class="custom-select">
                                                <option></option>
                                                <ng-container *ngFor="let tagsPositiveOption of tagsPositiveOptions">
                                                    <ng-container
                                                        *ngIf="!tagsPositiveOption.isOrgTag && tagsPositiveOption.owner == emailOnAccount">
                                                        <option [value]="tagsPositiveOption.id">{{tagsPositiveOption.name}}
                                                        </option>
                                                    </ng-container>
                                                </ng-container>
                                            </select>
                                        </label>
                                        <br>
                                        <label>
                                            <input type="checkbox" name="associateWithOneSupplierOnly" value="false"
                                                [(ngModel)]="associateWithOneSupplierOnly" />
                                            Associate with one supplier only?
                                        </label>
                                        <br><br>
                                        <label>
                                            Is a likely customer of companies tagged
                                            with:
                                            <select *ngIf="tagsPositiveOptions && tagsPositiveOptions.length === 0" class="custom-select">
                                                <option>No Tags Available</option>
                                            </select>
                                            <select *ngIf="tagsPositiveOptions && tagsPositiveOptions.length > 0"
                                                [(ngModel)]="customerTagSelected" name="customerTagSelected" class="custom-select">
                                                <option></option>
                                                <ng-container *ngFor="let tagsPositiveOption of tagsPositiveOptions">
                                                    <ng-container
                                                        *ngIf="!tagsPositiveOption.isOrgTag && tagsPositiveOption.owner == emailOnAccount">
                                                        <option [value]="tagsPositiveOption.id">{{tagsPositiveOption.name}}
                                                        </option>
                                                    </ng-container>
                                                </ng-container>
                                            </select>
                                        </label><br>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    
                        <div class="card" [ngClass]="{'card-disabled': !hasPatentsAccess}">
                            <div class="card-header" id="patents-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#patents-collapse">
                                        <i class="fal fa-book" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Patents</span>
                                        <i class="fal float-right" [ngClass]="hasPatentsAccess? 'fa-bolt': 'fa-ban'"
                                            matTooltipPosition="above" style="margin-top: 4px;"
                                            [matTooltip]="hasPatentsAccess ? 'This is a Zint Power Tool': 'You do not have access to the Patents data'"></i>
                                    </button>
                                </h5>
                            </div>
                            <div id="patents-collapse" class="collapse" data-parent="#search-area">
                                <ng-container *ngIf="hasPatentsAccess">
                                    <div class="card-body">
                                        <br>
                                        <label>
                                            <input type="checkbox" name="hasAnyPatentRecord" value="false"
                                                [(ngModel)]="hasAnyPatentRecord" />
                                            Has Any Patent Record
                                        </label>
                                        <i class="fal float-right fa-question-circle" matTooltipPosition="above" style="margin-top: 4px;"
                                            [matTooltip]="'Companies with any patent of any status, past or present.'"></i>
                    
                                        <br>
                                        <label>
                                            <input type="checkbox" name="hasActivePatent" value="false" [(ngModel)]="hasActivePatent" />
                                            Has Active Patent
                                        </label>
                                        <i class="fal float-right fa-question-circle" matTooltipPosition="above" style="margin-top: 4px;"
                                            [matTooltip]="'Companies with granted patents that are not expired, revoked or ceased.'"></i>
                    
                                        <br>
                                        <br>
                                        Filed patent in the last:<br>
                                        <zint-ngx-slider #patentFiledWithinDaysSliderElement *ngIf="patentFiledWithinDaysOptions"
                                            [isRange]="false" postfix=" days" [min]="patentFiledWithinDaysOptions.at(0)"
                                            [max]="patentFiledWithinDaysOptions.at(-1)"
                                            (sliderChange)="updatePatentFiledWithinDays($event)"></zint-ngx-slider>
                                        Awarded patent in the last:<br>
                                        <zint-ngx-slider #patentAwardedWithinDaysSliderElement *ngIf="patentAwardedWithinDaysOptions"
                                            postfix=" days" [min]="patentAwardedWithinDaysOptions.at(0)"
                                            [max]="patentAwardedWithinDaysOptions.at(-1)"
                                            (sliderChange)="updatePatentAwardedWithinDays($event)"></zint-ngx-slider>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    
                        <div class="card" [ngClass]="{'card-disabled': !hasGovernmentContractsAccess}">
                            <div class="card-header" id="government-contracts-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#government-contracts-collapse">
                                        <i class="fal fa-file-signature" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Government
                                            Contracts</span>
                                        <i class="fal float-right" [ngClass]="hasGovernmentContractsAccess? 'fa-bolt': 'fa-ban'"
                                            matTooltipPosition="above" style="margin-top: 4px;"
                                            [matTooltip]="hasGovernmentContractsAccess ? 'This is a Zint Power Tool': 'You do not have access to Government Contracts data'"></i>
                                    </button>
                                </h5>
                            </div>
                            <div id="government-contracts-collapse" class="collapse" data-parent="#search-area">
                                <ng-container *ngIf="hasGovernmentContractsAccess">
                                    <div class="card-body">
                                        <label>
                                            <input type="checkbox" name="hasContract" value="false" [(ngModel)]="hasContract" />
                                            Has been awarded contract
                                        </label>
                                        <br><br>
                                        Contract Value:<br>
                                        <zint-ngx-slider #contractValueSliderElement *ngIf="contractValueOptions" prefix="£" maxPostfix="+"
                                            [min]="contractValueOptions.at(0)" [max]="contractValueOptions.at(-1)"
                                            (sliderChange)="updateContractValueRangeSelection($event)"></zint-ngx-slider>
                                        <br>
                                        Awarded in the last:<br>
                                        <zint-ngx-slider #contractAwardedWithinMonthsSliderElement
                                            *ngIf="contractAwardedWithinMonthsOptions" [isRange]="false" postfix=" months" maxPostfix="+"
                                            [min]="contractAwardedWithinMonthsOptions.at(0)"
                                            [max]="contractAwardedWithinMonthsOptions.at(-1)"
                                            (sliderChange)="updateContractAwardedWithinMonths($event)"></zint-ngx-slider>
                                        <br>
                                        Contract starts within:<br>
                                        <zint-ngx-slider #contractStartMonthsSliderElement *ngIf="contractStartMonthsOptions"
                                            postfix=" months" maxPostfix="+" [min]="contractStartMonthsOptions.at(0)"
                                            [max]="contractStartMonthsOptions.at(-1)"
                                            (sliderChange)="updateContractStartMonthsRangeSelection($event)"></zint-ngx-slider>
                                        <br>
                                        Contract ends within:<br>
                                        <zint-ngx-slider #contractEndMonthsSliderElement *ngIf="contractEndMonthsOptions" postfix=" months"
                                            maxPostfix="+" [min]="contractEndMonthsOptions.at(0)" [max]="contractEndMonthsOptions.at(-1)"
                                            (sliderChange)="updateContractEndMonthsRangeSelection($event)"></zint-ngx-slider>
                                        <br>
                                        Contract Title Contains:
                                        <input type="text" class="form-control" name="contractTitleContains"
                                            [(ngModel)]="contractTitleContains"
                                            (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"><br>
                                        Contract Description Contains:
                                        <input type="text" class="form-control" name="contractDescriptionContains"
                                            [(ngModel)]="contractDescriptionContains"
                                            (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"><br>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    
                        <div class="card" [ngClass]="{'card-disabled': !hasCompanyChargesAccess}">
                            <div class="card-header" id="company-charges-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#company-charges-collapse">
                                        <i class="fal fa-university" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Company
                                            Charges</span>
                                        <i class="fal float-right" [ngClass]="hasCompanyChargesAccess? 'fa-bolt': 'fa-ban'"
                                            matTooltipPosition="above" style="margin-top: 4px;"
                                            [matTooltip]="hasCompanyChargesAccess ? 'This is a Zint Power Tool': 'You do not have access to Company Charges data'"></i>
                                    </button>
                                </h5>
                            </div>
                            <div id="company-charges-collapse" class="collapse" data-parent="#search-area">
                                <ng-container *ngIf="hasCompanyChargesAccess">
                                    <div class="card-body">
                                        Charge Holder Name Contains:
                                        <input type="text" class="form-control" name="chargeHolderNameContains"
                                            [(ngModel)]="chargeHolderNameContains"
                                            (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"><br>
                                        Charge Created:<br>
                                        <zint-ngx-slider #chargeCreatedSliderElement *ngIf="chargeCreatedMonthLimits" postfix=" months ago"
                                            [min]="chargeCreatedMonthLimits[0]" [max]="chargeCreatedMonthLimits[1]"
                                            (sliderChange)="updateChargeCreatedRangeSelection($event)"></zint-ngx-slider>
                                        <br>
                                        <label>
                                            <input type="checkbox" name="hasContract" value="false" [(ngModel)]="ignoreSatisfiedCharges" />
                                            Ignore Satisfied Charges
                                        </label>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    
                        <div class="card">
                            <div class="card-header" id="similar-company-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#similar-company-search-collapse">
                                        <i class="fal fa-vials" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Similar
                                            Companies</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="similar-company-search-collapse" class="collapse" data-parent="#search-area">
                                <div class="card-body">
                                    Enter the URL of any business.<br>
                                    Our machine-learning powered algorithm will analyse
                                    the content of that website and find
                                    similar businesses:<br>
                                    <input type="text" class="form-control" name="similarCompanyUrl" [(ngModel)]="similarCompanyUrl"
                                        style="width: 200px; display: inline" placeholder="e.g. zint.io"
                                        (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"><br>
                                </div>
                            </div>
                        </div>
                    
                        <div class="card">
                            <div class="card-header" id="tags-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#tags-search-collapse">
                                        <i class="fal fa-tag" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Tags</span>
                                    </button>
                                </h5>
                            </div>
                    
                            <div id="tags-search-collapse" class="collapse" data-parent="#search-area">
                                <div class="card-body">
                    
                                    <label class="xs-margin-bottom">
                                        <input type="checkbox" name="excludeCompaniesTaggedByTeam" value="false"
                                            [(ngModel)]="excludeCompaniesTaggedByTeam" />
                                        Exclude organisations tagged by team members
                                    </label><br><br>
                    
                                    <span *ngIf="tagsPositiveOptions && tagsPositiveOptions.length == 0">You
                                        have not yet tagged
                                        any businesses. Why don't you <button class="custom-button-a-link"
                                            style="margin-left: 0px; text-decoration: underline" data-toggle="modal"
                                            data-target="#tagModal">create a
                                            tag?</button></span>
                                    <div class="form-group select-table overflowing-table"
                                        *ngIf="!tagsPositiveOptions || tagsPositiveOptions.length != 0">
                                        <label for="options">Is tagged:</label>
                                        <div *ngFor="let tagPositiveOption of tagsPositiveOptions">
                                            <div>
                                                <label>
                                                    <input type="checkbox" [attr.name]="'tagPositiveOption' + tagPositiveOption.id"
                                                        [ngModelOptions]="{standalone: true}" value="{{tagPositiveOption.id}}"
                                                        [(ngModel)]="tagPositiveOption.checked" />
                                                </label>
                                                <span>
                                                    &nbsp;&nbsp;
                                                    <span class="fa-stack fa-2x" style="font-size: 0.5em"
                                                        *ngIf="tagPositiveOption.isOrgTag">
                                                        <i class="fal fa-tag fa-stack-2x"></i>
                                                        <i class="fal fa-users fa-stack-1x"></i>
                                                    </span>
                                                    <i class="fal fa-tag" *ngIf="!tagPositiveOption.isOrgTag"></i>
                                                    {{tagPositiveOption.name}}
                                                    <span *ngIf="isSuperUser"> -
                                                        {{tagPositiveOption.owner}}
                                                    </span>
                                                    <span style="float: right">- created
                                                        {{tagPositiveOption.created |
                                                        date: 'd MMM y' }}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                    
                                    <div class="form-group select-table overflowing-table"
                                        *ngIf="!tagsNegativeOptions || tagsNegativeOptions.length != 0">
                                        <label for="options">Is not tagged:</label>
                                        <div *ngFor="let tagNegativeOption of tagsNegativeOptions">
                                            <div>
                                                <label>
                                                    <input type="checkbox" [attr.name]="'tagNegativeOption' + tagNegativeOption.id"
                                                        [ngModelOptions]="{standalone: true}" value="{{tagNegativeOption.id}}"
                                                        [(ngModel)]="tagNegativeOption.checked" />
                                                </label>
                                                <span>
                                                    &nbsp;&nbsp;
                                                    <span class="fa-stack fa-2x" style="font-size: 0.5em"
                                                        *ngIf="tagNegativeOption.isOrgTag">
                                                        <i class="fal fa-tag fa-stack-2x"></i>
                                                        <i class="fal fa-users fa-stack-1x"></i>
                                                    </span>
                                                    <i class="fal fa-tag" *ngIf="!tagNegativeOption.isOrgTag"></i>
                                                    {{tagNegativeOption.name}}
                                                    <span *ngIf="isSuperUser"> -
                                                        {{tagNegativeOption.owner}}
                                                    </span>
                                                    <span style="float: right">- created
                                                        {{tagNegativeOption.created |
                                                        date:'d MMM y' }}</span></span>
                                            </div>
                                        </div>
                                    </div>
                    
                                </div>
                            </div>
                        </div>
                    
                        <div class="card">
                            <div class="card-header" id="custom-columns-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#custom-columns-collapse">
                                        <i class="fal fa-columns" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Your
                                            Custom Columns</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="custom-columns-collapse" class="collapse" data-parent="#search-area">
                                <div class="card-body">
                                    <custom-column-filter
                                        [inputParams]="{'customColumns': customColumns, 'filterOnAttribute': 'is_basic_column', 'equalsOrNot': true, 'teamMembers': teamMembersForCustomColumns}"></custom-column-filter>
                                </div>
                            </div>
                        </div>
                    
                        <div class="card">
                            <div class="card-header" id="alerts-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#alerts-search-collapse">
                                        <i class="fal fa-bell" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Alerts</span>
                                    </button>
                                </h5>
                            </div>
                    
                            <div id="alerts-search-collapse" class="collapse" data-parent="#search-area">
                                <div class="card-body">
                                    Organisations alerted in the range:
                                    <zint-ngx-slider #alertsDayRangeSliderElement *ngIf="alertsDaysRange" postfix=" days"
                                        [min]="alertsDaysRange[0]" [max]="alertsDaysRange[1]"
                                        (sliderChange)="updateAlertsDayRange($event)"></zint-ngx-slider>
                                    <br>
                    
                                    <label>
                                        <input type="checkbox" name="unreadAlertsOnly" value="false" [(ngModel)]="unreadAlertsOnly" />
                                        Show unread alerts only
                                    </label>
                    
                                    <br>
                    
                                    <div class="form-group select-table overflowing-table">
                                        <label>Alerts</label>
                                        <p *ngIf="alertOptions && alertOptions.length == 0">You
                                            have not created any alerts yet.
                                        </p>
                                        <div *ngFor="let alertOption of alertOptions">
                                            <div>
                                                <label>
                                                    <input type="checkbox" [attr.name]="'alertOption' + alertOption.id"
                                                        [ngModelOptions]="{standalone: true}" value="{{alertOption.id}}"
                                                        [(ngModel)]="alertOption.checked" />
                                                </label>
                                                <span>&nbsp;&nbsp;<i class="fal fa-bell"></i>
                                                    {{alertOption.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                    
                                </div>
                            </div>
                        </div>
                    
                        <div class="card">
                            <div class="card-header" id="heat-score-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#heat-score-collapse">
                                        <i class="fal fa-pepper-hot" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Heat
                                            Score</span>
                                    </button>
                                </h5>
                            </div>
                            <div id="heat-score-collapse" class="collapse" data-parent="#search-area">
                                <div class="card-body">
                                    <ng-container *ngFor="let slider of zintSlidersConfigs['heatScore']">
                                        <zint-ngx-slider #heatScoreSliders [sliderConfig]="slider"
                                            (sliderChange)="triggerConfigSliderUpdate($event, slider.label)"></zint-ngx-slider>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    
                        <div class="card" [ngClass]="{'card-disabled': !hasPropensityScoreAccess}">
                            <div class="card-header" id="propensity-score-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#propensity-score-collapse">
                                        <i class="fal fa-star" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Your
                                            Propensity Scores</span>
                                        <i class="fal float-right" [ngClass]="hasPropensityScoreAccess? 'fa-bolt': 'fa-ban'"
                                            matTooltipPosition="above" style="margin-top: 4px;"
                                            [matTooltip]="hasPropensityScoreAccess ? 'This is a Zint Power Tool': 'You do not have access to Propensity Modelling'"></i>
                                    </button>
                                </h5>
                            </div>
                            <div id="propensity-score-collapse" class="collapse" data-parent="#search-area">
                                <ng-container *ngIf="hasPropensityScoreAccess">
                                    <div class="card-body">
                                        <custom-column-filter
                                            [inputParams]="{'customColumns': customColumns, 'filterOnAttribute': 'is_propensity', 'equalsOrNot': true}"></custom-column-filter>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    
                        <div class="card" [ngClass]="{'card-disabled': !hasCrossSellAccess}">
                            <div class="card-header" id="cross-sell-tags-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#cross-sell-tags-search-collapse">
                                        <i class="fal fa-link" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Cross
                                            Sell Opportunities</span>
                                        <i class="fal float-right" [ngClass]="hasCrossSellAccess? 'fa-bolt': 'fa-ban'"
                                            matTooltipPosition="above" style="margin-top: 4px;"
                                            [matTooltip]="hasCrossSellAccess ? 'This is a Zint Power Tool': 'You do not have access to the Cross Sell Tool'"></i>
                                    </button>
                                </h5>
                            </div>
                            <div id="cross-sell-tags-search-collapse" class="collapse" data-parent="#search-area">
                                <ng-container *ngIf="hasCrossSellAccess">
                                    <div class="card-body">
                                        <label>
                                            <input type="checkbox" name="crossSellLinkedThroughPeople" value="false"
                                                [(ngModel)]="crossSellLinkedThroughPeople" />
                                            Is connected through shared directorships /
                                            individual share ownership?
                                        </label>
                    
                                        <label>
                                            <input type="checkbox" name="crossSellLinkedThroughCorporateStructure" value="false"
                                                [(ngModel)]="crossSellLinkedThroughCorporateStructure" />
                                            Is connected through the corporate
                                            structure?
                                        </label>
                    
                                        <span *ngIf="crossSellTagOptions && crossSellTagOptions.length == 0">You
                                            have not yet
                                            run a cross-sell analysis. Why don't you
                                            <button class="custom-button-a-link" style="margin-left: 0px;"
                                                href="/cross-sell-opportunities">run
                                                one?</button></span>
                                        <div class="form-group select-table overflowing-table">
                                            <label for="options">Cross Sell
                                                Option:</label>
                                            <div *ngFor="let crossSellTagOption of crossSellTagOptions">
                                                <div>
                                                    <label>
                                                        <input type="checkbox" [attr.name]="'crossSellTagOption' + crossSellTagOption.id"
                                                            [ngModelOptions]="{standalone: true}" value="{{crossSellTagOption.id}}"
                                                            [(ngModel)]="crossSellTagOption.checked" />
                                                    </label>
                                                    <span>&nbsp;&nbsp;<i class="fal fa-tag"></i>
                                                        {{crossSellTagOption.name}}
                                                        <span style="float: right">-
                                                            created
                                                            {{crossSellTagOption.created
                                                            |
                                                            date:'d MMM y'
                                                            }}</span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    
                        <div class="card" [ngClass]="{'card-disabled': !hasSalesforceV2AccessMessage}">
                            <div class="card-header" id="salesforce-columns-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#salesforce-columns-collapse">
                                        <i class="fab fa-salesforce salesforce-icon"
                                            [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Salesforce</span>
                                        <i class="fal float-right" [ngClass]="hasSalesforceV2AccessMessage? 'fa-bolt': 'fa-ban'"
                                            matTooltipPosition="above" style="margin-top: 4px;"
                                            [matTooltip]="hasSalesforceV2AccessMessage ? 'This is a Zint Power Tool': 'You do not have access to the Salesforce integration'"></i>
                                    </button>
                                </h5>
                            </div>
                            <div id="salesforce-columns-collapse" class="collapse" data-parent="#search-area">
                                <ng-container *ngIf="hasSalesforceV2AccessMessage">
                                    <div class="card-body">
                                        <ng-container *ngFor="let salesforceObject of salesforceObjects">
                                            <span class="xsmall-margin-bottom">{{salesforceObject.name}}</span>
                                            <div>
                                                <select [(ngModel)]="salesforceObject.dropdownInput" name="salesforceObjectDropDownChoice"
                                                    class="custom-select" style="width: 150px; margin-top: -3px; margin-right: 5px;">
                                                    <option value selected="selected"></option>
                                                    <option value="Exists">Exists</option>
                                                    <option value="DoesNotExist">Does
                                                        Not Exist</option>
                                                </select>
                                            </div>
                                        </ng-container>
                                        <hr>
                                        <custom-column-filter
                                            [inputParams]="{'customColumns': customColumns, 'filterOnAttribute': 'is_salesforce', 'equalsOrNot': true}"></custom-column-filter>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    
                        <div class="card">
                            <div class="card-header" id="accounts-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#accounts-search-collapse">
                                        <i class="fal fa-table" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i>
                                        <span *ngIf="!this.isSidebarCollapsed">Search
                                            Company Accounts</span>
                                        <i class="fal fa-bolt float-right shackleton-tooltip" matTooltipPosition="above"
                                            style="margin-top: 4px;" matTooltip="This is a Zint Power Tool"></i>
                                    </button>
                                </h5>
                            </div>
                            <div id="accounts-search-collapse" class="collapse" data-parent="#search-area">
                                <div class="card-body">
                                    <div>
                                        Search for businesses with the following phrases
                                        in their company accounts:<br>
                                        <span style="font-size: 10px">Use NOT for
                                            negative search, AND for multiple phrases,
                                            OR
                                            for different options, and inverted commas
                                            (") for exact phrases. Each term taken as
                                            OR by default.</span>
                                        <input type="text" class="form-control" name="accountsSearchTerm" [(ngModel)]="accountsSearchTerm"
                                            (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                                        <br>
                    
                                        Restrict to accounts submitted in the last:<br>
                                        <span style="font-size: 10px" *ngIf="!hasRecentAccountsAccess"><i class="fal fa-ban"></i> Your plan
                                            does
                                            not allow searching of accounts submitted
                                            in the most recent 12 months. Please <a
                                                href="mailto:sales@zint.io?Subject=Recent%20Accounts%20Search%20Enquiry">contact
                                                us</a> to upgrade.</span>
                                        <zint-ngx-slider #accountsSearchDayRangeSliderElement *ngIf="accountsSearchDayRange"
                                            [isRange]="false" postfix=" days" maxPostfix="+"
                                            [min]="hasRecentAccountsAccess ? accountsSearchDayRange[0]: 365"
                                            [max]="accountsSearchDayRange[1]"
                                            (sliderChange)="updateAccountsSearchDayRange($event)"></zint-ngx-slider>
                                    </div>
                                    <br>
                                </div>
                            </div>
                        </div>
                        <div class="card" [ngClass]="{'card-disabled': !hasSemanticSearchAccess}">
                            <div class="card-header" id="semantic-search-heading">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" role="button" data-toggle="collapse"
                                        data-target="#semantic-search-collapse">
                                        <i class="fal fa-dice-d20" [ngClass]="{'float-none': this.isSidebarCollapsed}"></i><span
                                            *ngIf="!this.isSidebarCollapsed" class>Semantic Search</span>
                                        <i class="fal float-right" [ngClass]="hasSemanticSearchAccess ? 'fa-bolt': 'fa-ban'"
                                            matTooltipPosition="above" style="margin-top: 4px;"
                                            [matTooltip]="hasSemanticSearchAccess ? 'This is a Zint Power Tool': 'You do not have access to the semantic search feature'"></i>
                                    </button>
                                </h5>
                            </div>
                            <div id="semantic-search-collapse" class="collapse">
                                <ng-container *ngIf="hasSemanticSearchAccess">
                                    <div class="card-body" data-parent="#search-area">
                                        <label for="summarySemanticSearchTerm" class="mb-0">Semantic search term</label>
                                        <input name="summarySemanticSearchTerm" id="summarySemanticSearchTerm" type="text"
                                            placeholder="term to include" class="form-control mb-1" [(ngModel)]="summarySemanticSearchTerm"
                                            (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)" />
                                        <label for="summarySemanticSearchExclude" class="mb-0">Semantic search term to
                                            exclude</label>
                                        <input name="summarySemanticSearchExclude" id="summarySemanticSearchExclude" type="text"
                                            placeholder="feature coming soon" class="form-control" disabled
                                            [(ngModel)]="summarySemanticSearchExclude"
                                            (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)" />
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row tab-pane" *ngIf="hasAISearchAccess" role="tabpanel" id="ai-chat-agent">
                    <div class="col-sm-12 pr-sm-0">
                        <chatbot></chatbot>
                    </div>
                </div>

            </div>


        </div>

        <!-- result table box -->
        <div class="order-4 col-md-8 overflow-scroll " id="table-results-section" style="margin-bottom: 20px;"
            [ngClass]="{'col-md-8': !this.isSidebarCollapsed, 'col-md-11': this.isSidebarCollapsed}">

            <table class="table table-striped table-bordered" id="fake-header-section"
                *ngIf="showFakeCompanyNameAndCheckboxSection">
                <th scope="col" *ngIf="companies && companies.length > 0"
                    id="fake-checkbox-header-cell">
                    <input type="checkbox" name="checkboxSelectAllCompanies" value="true"
                        [(ngModel)]="checkboxSelectAllCompanies"
                        (ngModelChange)="showOrHideInfoOnAllSelectedCompanies()" />
                </th>
                <th scope="col"
                    [ngStyle]="{'width': columnNamesToWidths['Company Name'] + 3 + 'px !important'}"
                    id="fake-company-name-header-cell">
                    Company Name
                </th>
            </table>

            <table class="table table-striped table-bordered company-results-table" style="margin-bottom: 0"
                id="company-results-table-header"
                [ngStyle]="{'margin-left': companyResultsTableHeaderRowMarginLeft + 'px'}"
                [ngClass]="{'table-loading': isLoading.loadingCompanies, 'fix-table': isCompanyResultsTableHeaderRowFixed, 'company-results-table-with-fixed-headers': freezeHeadersIsActive, 'company-results-table-header-few-columns-setting': isCompanyResultsTableHeaderRowFixed}">
                <thead>
                    <tr>
                        <th scope="col" *ngIf="companies && companies.length > 0"
                            style="text-align: center"
                            [ngClass]="{'hidden-checkbox-and-company-name-headers': showFakeCompanyNameAndCheckboxSection}">
                            <input type="checkbox" name="checkboxSelectAllCompanies" value="true"
                                [(ngModel)]="checkboxSelectAllCompanies"
                                (ngModelChange)="showOrHideInfoOnAllSelectedCompanies()" />
                        </th>
                        <ng-container *ngIf="companies && companies.length > 0">
                            <ng-container
                                *ngFor="let first_company_k_v of companies[0] | mapToIterable; let columnIndex = index">
                                <th scope="col"
                                    *ngIf="columnsToHide.indexOf(first_company_k_v.key) < 0"
                                    [ngStyle]="{'width': columnNamesToWidths[first_company_k_v.key] + 'px'}"
                                    [ngClass]="{'clicked-table-row-or-column': clickedColumn == columnIndex, 'hidden-checkbox-and-company-name-headers': first_company_k_v.key == 'Company Name' && showFakeCompanyNameAndCheckboxSection}"
                                    style="position: relative"
                                    (mouseenter)="tableColumnNumberCurrentlyHovered = columnIndex;"
                                    (mouseleave)="tableColumnNumberCurrentlyHovered = null; mouseHasLeftHeaderCell($event, first_company_k_v.key)"
                                    (mousedown)="tableHeaderColumnNumberCurrentlyClicked = columnIndex"
                                    (mouseup)="tableHeaderColumnNumberCurrentlyClicked = null; columnResized($event, first_company_k_v.key)">
                                    <i class="fal fa-plus plus-column-button cursor-pointer"
                                        *ngIf="tableColumnNumberCurrentlyHovered == columnIndex"
                                        (click)="loadResultsView = true; showEditColumnsModal()"></i>
                                    <span *ngIf="companyColumnsNotAllowedToFilter.indexOf(first_company_k_v.key) < 0"
                                        class="cursor-pointer" style="margin-left: 2px; margin-right: 5px"
                                        [ngClass]="{'sort-column-selected': sortKey==first_company_k_v.key}"
                                        (click)="sortBy(first_company_k_v.key)">
                                        <i class="fal fa-sort-up"
                                            *ngIf="sortKey==first_company_k_v.key && sortDirectionIsAscending"></i>
                                        <i class="fal fa-sort-down"
                                            *ngIf="sortKey==first_company_k_v.key && !sortDirectionIsAscending"></i>
                                        <i class="fal fa-sort"
                                            *ngIf="sortKey!=first_company_k_v.key"></i>
                                    </span>
                                    <span
                                        *ngIf="customColumnNamesToObjects && customColumnNamesToObjects[first_company_k_v.key]">
                                        <ng-container
                                            *ngIf="customColumnNamesToObjects[first_company_k_v.key]['is_collaborative_column']">
                                            <i class="fal fa-users" style="font-size: 14px"></i>
                                            <ng-container
                                                *ngIf="customColumnNamesToObjects[first_company_k_v.key]['is_lockable']">
                                                <i class="fal fa-lock"
                                                    style="font-size: 14px; margin-left: 5px"></i>
                                            </ng-container>
                                        </ng-container>
                                    </span>
                                    <span class="col-title"
                                        [innerHTML]="first_company_k_v.key | columnNameToIcon"></span>
                                    <span class="wrap-button-outer-span"
                                        *ngIf="tableColumnNumberCurrentlyHovered == columnIndex"
                                        (click)="addOrRemoveColumnToWrappedColumnNumbers(columnIndex)">
                                        <span class="wrap-button-first-line">|</span>
                                        <div class="wrap-button-block"></div>
                                        <i class="fal fa-redo wrap-button-arrow"></i>|
                                    </span>
                                </th>
                            </ng-container>
                        </ng-container>
                        <th *ngIf="companies && companies.length == 0 && !isLoading.loadingCompanies">No
                            Companies Found
                        </th>
                        <th id="spacing-header-element"
                            *ngIf="isCompanyResultsTableHeaderRowFixed"></th>
                    </tr>
                </thead>
            </table>
            <table class="table table-striped table-bordered company-results-table"
                [ngClass]="{'table-loading': isLoading.loadingCompanies, 'company-results-table-with-fixed-headers': freezeHeadersIsActive}">
                <tbody>
                    <tr style="text-align: center; font-size: 50px; font-weight: 100"
                        *ngIf="isLoading && isLoading.loadingCompanies">
                        <td colspan="1000">
                            <div class="d-flex justify-content-center align-items-center">
                                <zint-loader></zint-loader>&nbsp;Loading
                                Companies
                            </div>
                        </td>
                    </tr>
                    <ng-container *ngIf="!isLoading.loadingCompanies">
                        <tr *ngFor="let company of companies; let rowIndex = index"
                            class="clickable-table cut-off">
                            <td [ngClass]="{'clicked-table-row-or-column': clickedRow == rowIndex}"
                                style="text-align: center">
                                <input type="checkbox" name="companySelectedOption"
                                    (change)="updateCountOfSelectedCompanies()"
                                    [(ngModel)]="company.checked" />
                            </td>
                            <ng-container
                                *ngFor="let company_k_v of company | mapToIterable; let columnIndex = index">
                                <td *ngIf="columnsToHide.indexOf(company_k_v.key) < 0"
                                    [ngStyle]="{'width': columnNamesToWidths[company_k_v.key] + 'px'}"
                                    (click)="clickedRow = rowIndex; clickedColumn = columnIndex"
                                    (mouseenter)="currentHoverRowName = company.id; currentHoverColumnName = company_k_v.key"
                                    (mouseleave)="currentHoverRowName = ''; currentHoverColumnName = ''"
                                    (dblclick)="updateCurrentlyEditingCols(company.id, company_k_v.key)"
                                    [ngClass]="{'highlighted-cell-column-and-row': clickedRow == rowIndex && clickedColumn == columnIndex,'clicked-table-heavy-horizontal-borders': clickedRow == rowIndex, 'clicked-table-heavy-vertical-borders': clickedColumn == columnIndex, 'wrap-column-text': wrappedTableColumnNumbers.indexOf(columnIndex) >= 0, 'no-padding-cell': currentlyEditingRowName == company.id && currentlyEditingColumnName == company_k_v.key}"
                                    class="relative-position">
                                    <i *ngIf="company.hasViewed && company_k_v.key == 'Company Name'" class="fal fa-eye"
                                        matTooltipPosition="above" matTooltip="You have viewed this company before"
                                        style="margin-right: 5px"></i>
                                    <i *ngIf="company.isPlaceholderCompany && columnIndex == 0" class="fal fa-lock-alt"
                                        matTooltipPosition="above" matTooltip="Please contact us to view more results"
                                        style="margin-right: 5px"></i>
                                    <ng-container
                                        *ngIf="customColumnNamesToObjects[company_k_v.key] && currentlyEditingRowName == company.id && currentlyEditingColumnName == company_k_v.key">
                                        <custom-column-edit [inputParams]="{
                                                    'customColumn': customColumnNamesToObjects[company_k_v.key],
                                                    'companyIds': [company.id],
                                                    'displayTight': true,
                                                    'inputValue': company_k_v.val ? company_k_v.val.value || company_k_v.val: '',
                                                    'isSuperUser': isSuperUser,
                                                    'lockablePermittedEditValue': emailOnAccount,
                                                    'teamMembers': teamMembersForCustomColumns
                                                }"
                                            (onCustomColumnSaved)="clearAllCurrentlyEditingAndUpdateValue($event[0], company_k_v)"></custom-column-edit>
                                    </ng-container>
                                    <div
                                        *ngIf="customColumnNamesToObjects[company_k_v.key] && customColumnNamesToObjects[company_k_v.key].is_propensity && company_k_v.val && company_k_v.val.value">
                                        {{ company_k_v.val.value | number:'1.1-1' }} %
                                    </div>
                                    <ng-container
                                        *ngIf="!customColumnNamesToObjects[company_k_v.key] || !customColumnNamesToObjects[company_k_v.key].is_propensity">
                                        <div style="display: inline"
                                            [innerHtml]="company_k_v.val | companyDataPipe:company_k_v.key:company.number:accountsSearchTerm:homepageSearchTerm:fullWebsiteSearchTerm:newsContaining"
                                            [ngClass]="{'blur-company-result': company.isPlaceholderCompany}"
                                            *ngIf="currentlyEditingRowName != company.id || currentlyEditingColumnName != company_k_v.key">
                                        </div>
                                    </ng-container>
                                    <i *ngIf="
                                        customColumnNamesToObjects[company_k_v.key] &&
                                        customColumnNamesToObjects[company_k_v.key]['can_edit'] &&
                                        currentHoverRowName == company.id &&
                                        currentHoverColumnName == company_k_v.key &&
                                        (currentlyEditingRowName != company.id || currentlyEditingColumnName != company_k_v.key)"
                                        class="fal fa-pencil right-align-edit cursor-pointer"
                                        style="margin-top: 3px;"
                                        (click)="updateCurrentlyEditingCols(company.id, company_k_v.key)"></i>
                                </td>
                            </ng-container>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>

    </div>
</div>

<mark-as-read-modal></mark-as-read-modal>
<tagging></tagging>
<downloads
    [inputParams]="{'totalCompaniesSelected': totalCompaniesSelected, 'totalCompanyCount': companyCount.count, 'hasSelectedTotalNumberMatchingFilter': hasSelectedTotalNumberMatchingFilter}">
</downloads>
<salesforce [inputParams]="{'maximumCompanies': companiesPerPage}"></salesforce>
<custom-column-bulk-edit
    [inputParams]="{'customColumns': customColumns, 'teamMembers': teamMembersForCustomColumns}"></custom-column-bulk-edit>
<salesforce-export-modal></salesforce-export-modal>

<div class="modal fade" id="propensityScoreTAMDefinition" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Define the Total Addressable Market</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <p>Before you can add individual scoring components to your propensity
                            model, you must specify
                            your Total Addressable Market (TAM).</p>
                        <p>This is defined as the highest level filter that all organisations must
                            match before any
                            analysis takes place, e.g. ">£1m in revenue" or
                            "manufacturing organisations". Your TAM
                            cannot exceed {{ propensityTAMLimitCount | number }} organisations.</p>
                    </div>
                    <div class="col-md-12 small-margin-top">
                        <div *ngIf="companyCount && !isLoading.loadingCompanyCount">
                            <span
                                *ngIf="companyCount && companyCount.count > propensityTAMLimitCount"
                                class="badge badge-danger" matTooltipPosition="above"
                                matTooltip="More than {{ propensityTAMLimitCount | number }} organisations">
                                <i class="fal fa-exclamation-triangle"></i>
                            </span>
                            <span *ngIf="!companyCount.exact">Estimated</span>
                            {{ companyCount.count | number}} organisations
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="card xsmall-margin-top" style="border-radius: 1px">
                            <div class="card-body overflow-scroll">
                                Current TAM filters: <search-parameters
                                    [inputParams]="{'searchTags': searchTags, 'enableClearing': false}"></search-parameters>
                            </div>
                        </div>
                        <div class="small-margin-top">
                            <span>Name your Propensity Model:</span>
                            <input type="text" class="form-control" name="propensityScoreTAMName"
                                [(ngModel)]="propensityScoreTAMName" placeholder>
                        </div>
                        <button type="button"
                            class="basic-button-base button-dark control-buttons small-margin-top"
                            (click)="definePropensityScoreTAM()"
                            [attr.disabled]="isLoadingZintPropensityScore.loadingIsSavingTAM || isLoading.loadingCompanyCount || companyCount.count > propensityTAMLimitCount ? 'disabled': null">
                            <i class="fal fa-spinner fa-spin"
                                *ngIf="isLoadingZintPropensityScore.loadingIsSavingTAM"></i> Create
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="editPropensityModelTAM" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-matTooltip">Edit the Total Addressable Market</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 small-margin-top">
                        <div *ngIf="companyCount && !isLoading.loadingCompanyCount">
                            <span
                                *ngIf="companyCount && companyCount.count > propensityTAMLimitCount"
                                class="badge badge-danger" matTooltipPosition="above"
                                matTooltip="More than {{ propensityTAMLimitCount | number }} organisations">
                                <i class="fal fa-exclamation-triangle"></i>
                            </span>
                            <span *ngIf="!companyCount.exact">Estimated</span>
                            {{ companyCount.count | number}} organisations
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="card xsmall-margin-top" style="border-radius: 1px">
                            <div class="card-body overflow-scroll">
                                New TAM filters: <search-parameters
                                    [inputParams]="{'searchTags': searchTags, 'enableClearing': false}"></search-parameters>
                            </div>
                        </div>
                        <div class="small-margin-top">
                            Choose a Propensity Model to overwrite the TAM:
                            <select *ngIf="propensityModels && propensityModels.length > 0"
                                [(ngModel)]="selectedPropensityModelIdToUpdateTAM"
                                name="selectedPropensityModelIdToUpdateTAM" class="custom-select">
                                <option *ngFor="let propensityModel of editablePropensityModels"
                                    [ngValue]="propensityModel.id">{{propensityModel.name}}</option>
                            </select>
                        </div>
                        <button type="button"
                            class="basic-button-base button-dark control-buttons small-margin-top"
                            (click)="updatePropensityModelTAM()"
                            [attr.disabled]="!selectedPropensityModelIdToUpdateTAM || isLoadingZintPropensityScore.loadingIsSavingTAM || isLoading.loadingCompanyCount || companyCount.count > propensityTAMLimitCount ? 'disabled': null">
                            <i class="fal fa-spinner fa-spin"
                                *ngIf="isLoadingZintPropensityScore.loadingIsSavingTAM"></i> Update
                            TAM
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="editColumnsModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title">Edit Columns</h1>
                <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <results-format-editor *ngIf="loadResultsView"
                            [inputParams]="{'isSuperUser': isSuperUser}"></results-format-editor>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<hubspot-export></hubspot-export>
<custom-insights></custom-insights>
