import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'key-people',
  templateUrl: './key-people.component.html',
  styleUrl: './key-people.component.css',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyPeopleComponent {
  @Input() person: Record<string, any> = {};
  @Input() personIndex: number;
  @Input() showAllPeople: boolean = false;
  @Input() totalCount: number = null;

  constructor() {}

  getRandomUserIconColours(givenIndex?: number) {
    const colourCombos = [
      { primary: '#346178', secondary: '#8FC3D8' },
      { primary: '#FC611F', secondary: '#9CD7AC' },
      { primary: '#8FC3D8', secondary: '#FDC00F' },
      { primary: '#FC611F', secondary: '#8FC3D8' },
      { primary: '#6D39A7', secondary: '#9CD7AC' },
      { primary: '#346178', secondary: '#FDC00F' },
      { primary: '#346178', secondary: '#FC611F' },
    ];
    let indexArgument = givenIndex ?? this.personIndex;
    let colourIndex = indexArgument % colourCombos.length;

    return {
      '--fa-primary-color': colourCombos[colourIndex].primary,
      '--fa-secondary-color': colourCombos[colourIndex].secondary,
    };
  }

  getControlNaturePercentage(): number {
    return (this.person.control_weighting / 5) * 100;
  }

  getControlNatureTooltip(): string {
    if (!this.person.natures_of_control.length) return;
    let tooltipText = '';
    this.person.natures_of_control.forEach((text: string) => {
      tooltipText += `${text}\n`;
    });

    return tooltipText;
  }
}
