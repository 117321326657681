<div class="financials-table-container">
  <table class="table financials-table table-layout-fixed">
    <thead>
      <tr class="header-row">
        <th scope="col" class="sticky-col"></th>
        @for (colHeader of tableData.columns; track $index) {
        <th scope="col" class="">{{colHeader}}</th>        
        }
      </tr>
    </thead>

    <tbody>
      @for (rowObj of tableData.rows; track $index) {
      <tr>
        <th scope="row" class="sticky-col text-wrap" [width]="80">{{getObjectKeyForRowHeader(rowObj)}}</th>
        @for (colHeader of tableData.columns; track $index) {
          @for (dataKV of rowObj | keyvalue; track $index) {            
              <td>
                @if(dataKV.value[colHeader]) {
                  {{dataKV.value[colHeader] | companyDataPipe: formatting}}
                }
                @else {
                  &#65343;&#65343;&#65343;&#65343;
                }
              </td>            
          }        
      }
      </tr>
      }
    </tbody>
  </table>
</div>