<div class="modal fade" id="generateCustomInsightsModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="generateCustomInsightsModalLabel"><i class="fal fa-microchip"></i> Generate Custom Insights</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <p>You are about to generate insights for {{ companyIds.length }} @if(companyIds.length === 1){company}@else{companies}. This will use {{ companyIds.length }} Zint AI @if(companyIds.length === 1){credit}@else{credits}.</p>
                        @if (!loadingState.loadingRequestCustomInsights){
                        <button class="d-block mx-auto my-3 rounded-btn-base rounded-btn-vivid-outline btn-p-lg" (click)="requestCustomInsights()">
                            <i class="fal fa-check mr-1"></i>Confirm
                        </button>
                        }
                    </div>
                </div>
                @if (loadingState.loadingRequestCustomInsights){
                    <div class="row">
                        <div class="col-md-12">
                            <div class="d-flex justify-content-center">
                                <zint-loader size="xSmall"></zint-loader>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
</div>
