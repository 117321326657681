<div [ngClass]="{'card small-margin-top': !newUI}" *ngIf="aiResearchAnswers?.length">
    <div *ngIf="!newUI" class="card-header">
        <h4>AI Research Assistant</h4>
    </div>
    <div [ngClass]="{'card-body card-body-overflow-double': !newUI}">

        <ng-container *ngIf="companyId && enableOpenQuestionChatBot">
            <div class="row">
                <div class="col-md-12">
                    <div class="answer-box">
                    <chatbot [companyNumber]="companyId" [containerClass]="'compressed-chatbot-container'" [header]="'Ask your own question:'" [endpoint]="'get_company_assistant_responses'"></chatbot>
                    </div>
                </div>
            </div>
        </ng-container>

        <div class="row">
            <ng-container *ngFor="let aiResearchAnswer of aiResearchAnswers">
                <div class="col-md-6 small-margin-bottom">
                    <div class="answer-box">
                        <h5>{{aiResearchAnswer.question}}</h5>
                        <hr>
                        <ng-container *ngIf="aiResearchAnswer.is_unknown; else elseBlock">
                            <p>Could not determine.</p>
                            <p><i class="fal fa-comments"></i> Commentary: {{aiResearchAnswer.commentary}}</p>
                        </ng-container>
                        <ng-template #elseBlock>
                            <div class="response-box text-center">
                                <span>{{aiResearchAnswer.answer}}</span>
                            </div>
                            <p>
                                <ng-container *ngIf="aiResearchAnswer.confidence > 1"><i class="fal fa-star"></i></ng-container>
                                <ng-container *ngIf="aiResearchAnswer.confidence > 3"><i class="fal fa-star"></i></ng-container>
                                <ng-container *ngIf="aiResearchAnswer.confidence > 5"><i class="fal fa-star"></i></ng-container>
                                <ng-container *ngIf="aiResearchAnswer.confidence > 7"><i class="fal fa-star"></i></ng-container>
                                <ng-container *ngIf="aiResearchAnswer.confidence > 9"><i class="fal fa-star"></i></ng-container>
                                <ng-container *ngIf="aiResearchAnswer.confidence % 2 !== 0"><i class="fal fa-star-half"></i></ng-container>
                                Confidence: {{aiResearchAnswer.confidence}}/10
                            </p>
                            <p><i class="fal fa-comments"></i> Commentary: {{aiResearchAnswer.commentary}}</p>
                            <p><i class="fal fa-quote-left"></i><span class="font-italic"> {{aiResearchAnswer.quote}} </span><i class="fal fa-quote-right"></i></p>
                            <p *ngIf="aiResearchAnswer.date"><i class="fal fa-calendar-check"></i> Approximate Reference Date: {{aiResearchAnswer.date}}</p>
                        </ng-template>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
