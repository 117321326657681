import { Component, Input } from '@angular/core';
import { IBackendDrivenTable } from '../../../classes/types';

@Component({
  selector: 'backend-table',
  templateUrl: './backend-table.component.html',
  styleUrl: './backend-table.component.css',
})
export class BackendTableComponent {
  @Input() tableData: IBackendDrivenTable;
  @Input() formatting: string = '';

  /*
data structure should match the following akin to /llm_energy_emissions_data/ response
  {
    columns: ['year 1', 'year 2', 'year 2'],
    rows: [
      { scope2: { 'year 1': 100, 'year 2': 300 } },
      { 'transport data': { 'year 1': 300 } },
      { 'scope3 energy usage data': { 'year 1': 300, 'year 2': 0, year: 1 } },
    ],
  };
  */

  getObjectKeyForRowHeader(obj: Record<string, any>): string {
    return Object.keys(obj)[0];
  }
}
