import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { baseUrl } from '../../globals';
import { CompanyService } from '../../services/company.service';

interface ChatMessage {
  sender: 'user' | 'response';
  message: string;
}

@Component({
  selector: 'chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.css'],
})
export class ChatbotComponent implements OnInit {
  @Input() header: string = 'Zint Agent';
  @Input() endpoint: string = 'get_ai_search_url';
  @Input() containerClass = 'chatbot-container';
  @Input() companyNumber: string;

  threadId: string = '';
  userInput: string = '';
  messageThread: ChatMessage[] = [];
  response: string = '';
  anchorTag: string = '';

  isLoading = false;

  dummyRes = `Here is your search link for companies in Derby: [Search Link](https://app.zint.io/dashboard?selectedCompanyStatuses=%5B"Active%20Companies"%5D&selectedTownId=E1mvN2UBb7E9h8y9uVeC)
`;

  streamedResponse: string = '';

  @ViewChild('chatbox') chatbox: ElementRef;
  constructor(private companyService: CompanyService) {}

  ngOnInit(): void {}

  scrollToBottom(): void {
    // need delay before scroll for other processes
    setTimeout(() => {
      this.chatbox.nativeElement.scrollTop =
        this.chatbox.nativeElement.scrollHeight;
    }, 10);
  }

  createThread() {
    this.companyService.createAssistantThread(this.companyNumber).subscribe(
      data => {
        if (data && data.thread_id) {
          this.threadId = data.thread_id;
          this.sendMessage();
        } else {
          // If no thread ID is returned, then try again, but only after 5 seconds.
          // OpenAI might be down or something
          setTimeout(() => {
            this.createThread();
          }, 5000);
        }
      },
      error => {
        // retry on error also
        setTimeout(() => {
          this.createThread();
        }, 5000);
      }
    );
  }

  sendMessage() {
    if (!this.userInput) return;
    if (!this.threadId) {
      this.createThread();
    }
    const userMsg = this.userInput;
    this.messageThread.push({ sender: 'user', message: userMsg });
    this.getSSE(userMsg);
    this.userInput = '';
    this.scrollToBottom();
  }

  showRes(userMsg?: string) {
    /* just a test method to not call openAI
     * use in sendMessage() instead of getSSE
     */
    this.isLoading = true;
    setTimeout(() => {
      this.messageThread.push({
        sender: 'response',
        message: this.dummyRes,
      });
      this.scrollToBottom();
      this.isLoading = false;
    }, 2000);
  }

  getSSE(userQuery: string) {
    this.isLoading = true;
    this.response = '';
    let url = baseUrl + '/' + this.endpoint + '/?query=' + userQuery;
    if (this.threadId != undefined) {
      url += '&thread_id=' + this.threadId;
    }
    const source = new EventSource(url, { withCredentials: true });
    source.addEventListener('message', message => {
      if (message.data) {
        const data = JSON.parse(message.data);
        if (data.content && !data.content.endsWith('source\u3011')) {
          this.response += data.content.replace('\n', '<br>');
        } else {
          console.log('===NOdata.content===');
          this.updateThreadAndClose(source);
        }
      } else {
        console.log('>>>No message.data<<<');
        this.updateThreadAndClose(source);
      }
    });

    source.addEventListener('error', _ => {
      // Stream ends in this error state for unknown reason but keep this as is.
      this.updateThreadAndClose(source);
    });
  }

  updateThreadAndClose(source: EventSource) {
    if (this.response.length) {
      this.messageThread.push({
        sender: 'response',
        message: this.response,
      });
    }
    source.close();
    this.isLoading = false;
    this.response = ''; // Reset the response

    this.scrollToBottom();
  }
}
