@if(newUI){ 
    @for (month of inputParams.detailedImportsOrExports; track $index) {
        <div class="card shadow-sm border-rounded-05 p-3 mb-2">
            <h5>{{month[0] |date: 'MMMM yyyy'}}</h5>
            @for (importExportDetails of month[1]; track $index) {
                <div class="d-flex justify-content-between align-items-start" [ngClass]="{'pt-3 border-top-05': $index}">
                    <p [innerHTML]="importExportDetails.description" class="mr-2"></p>
                    
                    <span class="badge-vivid-blue">Estimated Value: {{ importExportDetails.value?.toPrecision(3) | currency:'GBP':'symbol-narrow':'.0' }}

                    <i class="fal fa-question-circle ml-1"
                    matTooltipPosition="above"
                    [matTooltip]="tooltipText"
                    ></i>
                    </span>
                </div>
            }
        </div>
    }
}
@else {
    <!-- TODO: remove the old style -->
<div *ngFor="let importExportMonth of inputParams.detailedImportsOrExports">
    <h5>{{ importExportMonth[0] | date: 'MMMM yyyy'}}</h5>
    <table class="table table-striped">
        <tbody>
            <tr *ngFor="let importExportDetails of importExportMonth[1]">
                <td style="text-align: left">
                    <p [innerHTML]="importExportDetails.description"></p>
                </td>
                <td style="vertical-align: middle" *ngIf="importExportDetails.value">
                    <span class="badge badge-info" style="text-align: left; display: inline">Estimated Value: {{ importExportDetails.value.toPrecision(3) | currency:'GBP':'symbol-narrow':'.0' }}</span>
                    <div style="display: inline" matTooltipPosition="above" [matTooltip]="tooltipText"><i class="fal fa-question-circle" style="margin-left: 5px; font-size: 14px;"></i></div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
}

